<template>
  <div
    class="checkout-header d-flex flex-column flex-shrink-0 overflow-hidden mt-5"
    style="width:100%"
  >
    <div class="d-flex justify-center" style="width:100%">
      <v-slide-group mandatory active-class="active">
        <v-slide-item v-slot="{ toggle }">
          <router-link to="/asiakastili/yhteystiedot" class="checkout-step-item">
            <div class="d-flex check-out__stepper-item">
              <div class="d-flex flex-column align-center">
                <v-btn
                  class="mb-2"
                  text
                  tile
                  @click="toggle"
                  :icon="icon"
                  tabindex="-1"
                >
                  <v-icon class="mr-md-2">
                    ${{"Icon24Home"}}
                  </v-icon>

                  <span class="d-none d-md-inline">Asiakastiedot</span>
                </v-btn>
                <div class="angle-wrapper">
                  <div class="angle-up black--text"></div>
                </div>
              </div>
            </div>
          </router-link>
        </v-slide-item>
        <v-divider class="px-2" style="margin-top:18px"></v-divider>
        <v-slide-item v-slot="{ toggle }">
          <router-link to="/asiakastili/tilaukset" class="checkout-step-item">
            <div class="d-flex check-out__stepper-item">
              <div class="d-flex flex-column align-center">
                <v-btn
                  class="mb-2"
                  text
                  tile
                  @click="toggle"
                  :icon="icon"
                  tabindex="-1"
                >
                  <v-icon class="mr-md-2">
                    ${{"Icon24Box"}}
                  </v-icon>

                  <span class="d-none d-md-inline">Tilaukset</span>
                </v-btn>
                <div class="angle-wrapper">
                  <div class="angle-up black--text"></div>
                </div>
              </div>
            </div>
          </router-link>
        </v-slide-item>
        <v-divider class="px-2" style="margin-top:18px"></v-divider>
        <v-slide-item v-slot="{ toggle }">
          <router-link to="/asiakastili/asetukset" class="checkout-step-item">
            <div class="d-flex check-out__stepper-item">
              <div class="d-flex flex-column align-center">
                <v-btn
                  class="mb-2"
                  text
                  tile
                  @click="toggle"
                  :icon="icon"
                  :ripple="false"
                  tabindex="-1"
                >
                  <v-icon class="mr-md-2">
                    ${{"Icon16Sliders"}}
                  </v-icon>

                  <span class="d-none d-md-inline">Asetukset</span>
                </v-btn>
                <div class="angle-wrapper">
                  <div class="angle-up black--text"></div>
                </div>
              </div>
            </div>
          </router-link>
        </v-slide-item>
      </v-slide-group>
    </div>
    <v-divider class="black" style="margin-top:-1px"></v-divider>
  </div>
</template>

<style scoped>
.checkout-header .v-slide-group__next,
.checkout-header .v-slide-group__prev {
  padding-bottom: 18px;
}
.angle-wrapper {
  --angel-wrapper-width: 20px;
  padding: 1px;
  width: var(--angel-wrapper-width);
  height: calc(var(--angel-wrapper-width) / 2);
  overflow: hidden;
  background: transparent;
  opacity: 0;
  z-index: 1;
}

.checkout-header .checkout-step-item {
  opacity: 0.6;
}

.checkout-header .checkout-step-item.router-link-exact-active {
  opacity: 1;
}

.checkout-header .checkout-step-item.router-link-exact-active .angle-wrapper {
  opacity: 1;
}

.angle-up {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  background: white;
  border-top: 1px solid currentColor;
  border-left: 1px solid currentColor;
  margin-top: 18%;
  transform: rotate(45deg);
  box-sizing: content-box;
}

.check-out__stepper-item:last-child .v-divider {
  display: none;
}
</style>

<script>
export default {
  data() {
    return {
      toggle_exclusive: undefined,
    };
  },
  computed: {
    icon() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "icon";
        case "xs":
          return "icon";
        default:
          return false;
      }
    },
    iconLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return false;
        case "xs":
          return false;
        default:
          return true;
      }
    },
  },
};
</script>
