var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-out d-flex flex-1 flex-column overflow-hidden",staticStyle:{"width":"100%"}},[(_vm.$route.params.step != 'confirmation')?_c('CheckOutHeader'):_vm._e(),_c('div',{staticClass:"d-flex mb-11 pb-10 checkout-main-view"},[(_vm.$route.params.step == 'ostoskori')?_c('CheckOutCart'):_vm._e(),(_vm.$route.params.step == 'yhteystiedot')?_c('CheckOutContactForm'):_vm._e(),(_vm.$route.params.step == 'toimitustapa')?_c('CheckOutDelivery'):_vm._e(),(_vm.$route.params.step == 'maksutapa')?_c('CheckOutPayment'):_vm._e(),(_vm.$route.params.step == 'yhteenveto')?_c('CheckOutSummary'):_vm._e(),(_vm.$route.params.step == 'confirmation')?_c('CheckOutConfimation'):_vm._e(),(
        !(
          _vm.$route.params.step == 'yhteenveto' ||
          _vm.$route.params.step == 'confirmation'
        )
      )?_c('v-divider',{staticClass:"hidden-sm-and-down",attrs:{"vertical":""}}):_vm._e(),(
        !(
          _vm.$route.params.step == 'yhteenveto' ||
          _vm.$route.params.step == 'confirmation'
        )
      )?_c('CheckOutSmallSummary'):_vm._e()],1),(
      !(
        _vm.$route.params.step == 'yhteenveto' ||
        _vm.$route.params.step == 'confirmation'
      )
    )?_c('CheckOutControls'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }