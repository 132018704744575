<template>
<div>
    <div v-if="this.data===null || Object.keys(this.data).length===0|| this.data.total==0 ">
        <v-card outlined
        class="order-card px-8 py-8">
            <div class="info-and-summary">
                <div class="order-info">
                    <div class="title font-weight-light">Ei näytettäviä tilauksia</div>
                </div>
            </div>
        </v-card>
    </div>
    <div v-else>
        <v-card
        v-for="item in this.data.items"
        :key="item.sensibleOrderId"
        outlined
        class="order-card px-8 py-8">
            <div class="info-and-summary">
            <div class="order-info">
                <div class="title font-weight-light">Tilausnumero:</div>
                    <h2 class="font-weight-regular">#{{item.sensibleOrderId}}</h2>
                <v-chip
                v-if="item.workflow.currentStep == 'processing'"
                class="text-center d-block mt-2"
                >
                Vastaanotettu
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'collected' || 
                (item.workflow.currentStep == 'completed' && item.workflow.shippingMethod=='STORE_PICKUP')"
                class="text-center d-block mt-2"
                >
                Odottaa noutoa
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'on-hold-store'"
                class="text-center d-block mt-2"
                >
                Vastaanotettu
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'on-hold'"
                class="text-center d-block mt-2"
                >
                Vastaanotettu
                </v-chip>     
                <v-chip
                v-if="item.workflow.currentStep == 'failed'"
                class="text-center d-block mt-2"
                >
                Maksu hylätty
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'canceled'"
                class="text-center d-block mt-2"
                >
                Peruutettu
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'completed'"
                class="text-center d-block mt-2"
                >
                Valmis
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'refunded'"
                class="text-center d-block mt-2"
                >
                Tilaus palautettu
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'archived'"
                class="text-center d-block mt-2"
                >
                Valmis
                </v-chip>
                <v-chip
                v-if="item.workflow.currentStep == 'placed'"
                class="text-center d-block mt-2"
                >
                Vastaanotettu
                </v-chip>
                </div>
            <div class="item-summary">
                <div class="title font-weight-light">Tilauksen tiedot:</div>
                <p><span class="font-weight-light">Tilauspäivä</span> {{converTimeStamp(item.created)}}</p>
                <p><span class="font-weight-light">Tuotteiden määrä:</span> {{item.orderDetails.items.length-1}}</p>
                <p><span class="font-weight-light">Kokonaishinta:</span> <span class="price">{{item.orderDetails.toPay.toFixed(2)}}€</span></p>
            </div>
            </div>
                
            <div>
                <router-link :to="{name: 'user-order', params: {tab: 'tilauksen_id', uuid: item.uuid}}"><v-btn outlined rounded color="black">Avaa tilaus <v-icon right size="16"> ${{ "Icon16ArrowRight" }} </v-icon></v-btn></router-link>
            </div>
        </v-card>
        </div>
</div>
</template>
<style scoped>

h2 {
    font-weight: 300;
}

.order-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
}

.info-and-summary {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.order-card:not(:first-child) {
    border-top: none;
}

.order-card .order-info p, .order-card .item-summary p {
    margin: 0;
}

.order-card .item-summary {
    text-align: left;
    font-weight: 400;
    margin: 0;
}

.order-card .item-summary .item-amount {
    font-weight: 300;
    font-size: 1.3rem;
}

.order-card .item-summary .price {
    font-size: 1rem;
}

.wrapper {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    .info-and-summary {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-and-summary .item-summary {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 400px) {


    .order-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .order-card button {
        margin-top: 25px;
    }

}

</style>

<script>
export default{
    data() {
        return {
            data: this.$store.state.orders.orders,
            orderUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/order/orders/",
        }
        }, methods: {
            converTimeStamp: function(timestamp) {
                var date = timestamp.split('T')[0].split('-')
                //var parsed = date.getDate() +"."+(date.getMonth()+1)+"."+date.getFullYear()
                return date[2]+"."+date[1]+"."+date[0]
            }
        }, mounted() {
            this.axios.get(this.orderUrl+`${this.$store.state.login.uuid}`).then(response => this.$store.commit("addOrders", response.data))
            this.$store.state.orders.orders.items.sort((a,b) => (a.created <= b.created) ? 1 : -1)
        }
    }
</script>