<template>
  <div
    class="checkout-contact-form d-flex flex-column align-center flex-grow-1 overflow-hidden my-5 pa-5"
    style="width: 100%"
  >
    <v-card width="100%" max-width="400" flat tile>
      <v-form>
        <h2 class="mb-5 font-weight-light">Yhteystiedot</h2>
        <div class="py-3">Tilaajan tiedot</div>
        <v-text-field
          outlined
          dense
          label="Nimi"
          required
          :rules="customerNameCodeRules"
          v-model="customerName"
        >
        </v-text-field>
        <v-text-field outlined dense v-model="customerCompany">
          <template v-slot:label>
            <div>Yritys <small>(Ei pakollinen)</small></div>
          </template>
        </v-text-field>

        <div class="py-3">Osoite</div>
        <v-text-field
          outlined
          dense
          label="Katuosoite"
          required
          :rules="customerAddrCodeRules"
          v-model="customerAddr"
        >
        </v-text-field>
        <v-text-field outlined dense v-model="customerAddr2">
          <template v-slot:label>
            <div>Osoitelisärivi <small>(Ei pakollinen)</small></div>
          </template>
        </v-text-field>

        <v-row>
          <v-col cols="5">
            <v-text-field
              outlined
              dense
              label="Postinumero"
              required
              :rules="customerPostalCodeRules"
              v-model.number="customerPostalCode"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="7">
            <v-text-field
              outlined
              dense
              label="Postitoimipaikka"
              required
              :rules="customerPostalOfficeRules"
              v-model="customerPostalOffice"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div class="pb-3">Toimitus eri osoitteeseen?</div>
        <v-btn
          rounded
          outlined
          v-on:click="seperateDeliveryAddress = !seperateDeliveryAddress"
        >
          {{seperateDeliveryAddress ? 'Poista toimitusosoite ': 'Lisää toimitusosoite'}}
        </v-btn>
        <div v-if="seperateDeliveryAddress">
          <div class="py-3">Toimitusosoite</div>
          <v-text-field
            outlined
            dense
            label="Katuosoite"
            required
            :rules="customerAddrCodeRules"
            v-model="customerDeliveryAddr"
          >
          </v-text-field>
          <v-text-field outlined dense v-model="customerDeliveryAddr2">
            <template v-slot:label>
              <div>Osoitelisärivi <small>(Ei pakollinen)</small></div>
            </template>
          </v-text-field>

          <v-row>
            <v-col cols="5">
              <v-text-field
                outlined
                dense
                label="Postinumero"
                required
                :rules="customerPostalCodeRules"
                v-model.number="customerDeliveryPostalCode"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="7">
              <v-text-field
                outlined
                dense
                label="Postitoimipaikka"
                required
                :rules="customerPostalOfficeRules"
                v-model="customerDeliveryPostalOffice"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>

        <div class="py-3">Yhteystiedot</div>
        <v-text-field
          outlined
          dense
          label="Puhelinnumero"
          required
          :rules="customerPhoneNumberRules"
          v-model="customerPhoneNumber"
        >
        </v-text-field>
        <v-text-field
          v-model="customerEmail"
          :rules="emailRules"
          outlined
          dense
          label="Sähköposti"
          required
        >
        </v-text-field>

        <v-checkbox
          v-model="registerCheckbox"
          label="Rekisteröidy käyttäjäksi"
        ></v-checkbox>
        <!-- TODO Add validation for password -->
        <div v-if="registerCheckbox">
          <v-text-field
            v-model="customerPassword"
            outlined
            dense
            label="Salasana"
            required
            :type="password"
          >
          </v-text-field>
          <v-text-field
            v-model="customerPassword"
            outlined
            dense
            label="Salasana uudelleen"
            required
            :type="password"
          >
          </v-text-field>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    email: "",
    emailRules: [
      (v) => !!v || "Sähköposti on pakollinen",
      (v) => /.+@.+/.test(v) || "Sähköposti pitää olla oikeassa muodossa",
    ],
    customerPostalCodeRules: [
      (v) => !!v || "Postinumero on pakollinen",
      (v) => /^\d{5}$/.test(v) || "Postinumero pitää olla oikeassa muodossa",
    ],
    customerNameCodeRules: [(v) => !!v || "Nimi on pakollinen"],
    customerAddrCodeRules: [(v) => !!v || "Osoite on pakollinen"],
    customerPostalOfficeRules: [(v) => !!v || "Postitoimipaikka on pakollinen"],
    customerPhoneNumberRules: [
      (v) => !!v || "Puhelinnumeron on pakollinen",
      (v) =>
        /^[+358|+46]?\d{6,15}$/.test(v) ||
        "Postinumero pitää olla oikeassa muodossa",
    ],
    registerCheckbox: false,
  }),
  computed: {
    seperateDeliveryAddress: {
      get() {
        return this.$store.state.sperateDelivery;
      },
      set(value) {
        this.$store.commit("updateSperateDelivery", value);
      },
    },
    customerName: {
      get() {
        return this.$store.state.customername;
      },
      set(value) {
        this.$store.commit("updateCustomerName", value);
      },
    },
    customerCompany: {
      get() {
        return this.$store.state.customercompany;
      },
      set(value) {
        this.$store.commit("updateCustomerCompany", value);
      },
    },
    customerAddr: {
      get() {
        return this.$store.state.customeraddr;
      },
      set(value) {
        this.$store.commit("updateCustomerAddr", value);
      },
    },
    customerAddr2: {
      get() {
        return this.$store.state.customeraddr2;
      },
      set(value) {
        this.$store.commit("updateCustomerAddr2", value);
      },
    },
    customerPostalCode: {
      get() {
        return this.$store.state.customerpostalcode;
      },
      set(value) {
        this.$store.commit("updateCustomerPostalCode", value);
      },
    },
    customerPostalOffice: {
      get() {
        return this.$store.state.customerpostaloffice;
      },
      set(value) {
        this.$store.commit("updateCustomerPostalOffice", value);
      },
    },
    customerPhoneNumber: {
      get() {
        return this.$store.state.customerphonenumber;
      },
      set(value) {
        this.$store.commit("updateCustomerPhoneNumber", value);
      },
    },
    customerEmail: {
      get() {
        return this.$store.state.customeremail;
      },
      set(value) {
        this.$store.commit("updateCustomerEmail", value);
      },
    },
    customerDeliveryAddr: {
      get() {
        return this.$store.state.customerdeliveryaddr;
      },
      set(value) {
        this.$store.commit("updateCustomerDeliveryAddr", value);
      },
    },
    customerDeliveryAddr2: {
      get() {
        return this.$store.state.customerdeliveryaddr2;
      },
      set(value) {
        this.$store.commit("updateCustomerDeliveryAddr2", value);
      },
    },
    customerDeliveryPostalCode: {
      get() {
        return this.$store.state.customerdeliverypostalcode;
      },
      set(value) {
        this.$store.commit("updateCustomerDeliveryPostalCode", value);
      },
    },
    customerDeliveryPostalOffice: {
      get() {
        return this.$store.state.customerdeliverypostaloffice;
      },
      set(value) {
        this.$store.commit("updateCustomerDeliveryPostalOffice", value);
      },
    },
  },
};
</script>
