<template>
  <div
    class="d-flex flex-column check-out-cart flex-grow-1 overflow-hidden pb-4"
  >
    <div id="my-checkout-container"></div>
  </div>
</template>

<style scoped>
.v-item--active .chevron-up-icon {
  transform: rotate(-180deg);
}
</style>

<script>
export default {
  data: () => ({
    toggle: false,
  }),
  created: function() {
    // console.log(this.$route.params.order);
    const url =
      process.env.VUE_APP_BBSHOP_ENDPOINT +
      "/klarna-checkout/read/" +
      this.$route.params.order;
    this.axios
      .get(url, {})
      .then((result) => {
        const htmlSnippet = result.data.item;
        const checkoutContainer = document.getElementById(
          "my-checkout-container"
        );
        checkoutContainer.innerHTML = htmlSnippet;
        const scriptsTags = checkoutContainer.getElementsByTagName("script");
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (const scriptsTag of scriptsTags) {
          const parentNode = scriptsTag.parentNode;
          const newScriptTag = document.createElement("script");
          newScriptTag.type = "text/javascript";
          newScriptTag.text = scriptsTag.text;
          parentNode.removeChild(scriptsTag);
          parentNode.appendChild(newScriptTag);
        }
        this.$store.commit("emptyShoppingCart");
        this.$store.commit("resetOrderInfo");
      })
      .catch((err) => console.log(err));
  },
};
</script>
