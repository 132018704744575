<template>
  <div
    v-if="!purchaseComplete"
    class="d-flex flex-column check-out-cart flex-grow-1 overflow-hidden pb-4"
  >
    <h2 class="pa-5 font-weight-light">Yhteenveto</h2>
    <v-divider></v-divider>
    <CheckOutSummaryItem
      v-for="(item, i) in this.$store.state.cart.shoppingCart"
      :key="i"
      :item="item"
    />
    <v-divider></v-divider>
    <div class="d-md-flex padded justify-space-between">
      <div class="d-md-flex">
        <p class="font-weight-bold my-0">Toimitustapa</p>
        <p class="mx-md-4 my-0">{{ `${this.$store.state.deliveryMethod}` }}</p>
      </div>
      <span class="font-weight-bold"
        >{{ `${this.$store.state.deliveryCost}` }}€</span
      >
    </div>
    <v-divider></v-divider>
    <div class="d-md-flex padded flex-wrap justify-space-between">
      <div class="d-md-flex flex-wrap">
        <p class="font-weight-bold my-0">Maksutapa</p>
        <p class="mx-md-4 my-0">Maksu noudettaessa</p>
      </div>
      <!--<span class="font-weight-bold">0,00€</span>-->
    </div>
    <v-divider></v-divider>
    <div class="d-md-flex padded flex-wrap justify-space-between">
      <div>
        <p class="font-weight-bold my-0">Yhteystiedot noutoa varten</p>
        <p class="my-0">{{ `${this.$store.state.contactInfo.name}` }}</p>
        <p class="my-0">{{ `${this.$store.state.contactInfo.phoneNumber}` }}</p>
        <p class="my-0">
          {{ `${this.$store.state.contactInfo.emailAddress}` }}
        </p>
      </div>
      <!--<span class="font-weight-bold">0,00€</span>-->
    </div>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <div class="d-flex justify-end pa-4 align-end">
      yht.
      <span class="text-h5 ml-2 font-weight-bold">{{ totalPrice }}€</span>
    </div>

    <!--<CheckOutSummaryInfoBoxes class="mt-8" />-->

    <!-- Checkout summary controls -->
    <div class="d-flex flex-column check-out-controls white">
      <div class="d-flex justify-space-between align-center pa-6 container">
        <router-link :to="previousTab">
          <v-btn outlined rounded>
            <v-icon left> mdi-arrow-left </v-icon>
            Takaisin
          </v-btn>
        </router-link>

        <div class="d-flex px-4 flex-wrap check-out-controls__total">
          <div class="d-flex align-center">
            <v-checkbox v-model="isDeliveryTermsAccepted"> </v-checkbox>
            <p class="my-0">
              Hyväksyn
              <router-link to="/tilaus-ja-toimitusehdot" target="_blank"
                >toimitusehdot</router-link
              >
            </p>
          </div>
        </div>
        <v-btn
          :disabled="!isDeliveryTermsAccepted"
          rounded
          color="primary"
          depressed
          @click="startOrder"
        >
          Vahvista
          <v-icon right> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
  <v-card
    outlined
    tile
    v-else
    class="mt-12 pa-12"
    style="max-width: 650px; margin: 0 auto"
  >
    <h1 style="font-size: 1.5rem">Kiitos tilauksesta!</h1>
    <p class="my-4">Lähetämme vielä sähköpostiisi tilausvahvistuksen.</p>
    <v-btn link color="primary" rounded to="/" large>Palaa etusivulle</v-btn>
  </v-card>
</template>

<style scoped>
.padded {
  padding: 16px 16px 16px 166px;
}

@media only screen and (max-width: 1263px) {
  .padded {
    padding: 16px 16px 16px 136px;
  }
}

@media only screen and (max-width: 959px) {
  .padded {
    padding: 16px 16px 16px 116px;
  }
}

@media only screen and (max-width: 599px) {
  .padded {
    padding: 16px 16px 16px 86px;
  }
}

.coupon-field__btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.coupon-field__input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.check-out-cart .v-slide-group__content .product-card {
  min-width: 100px !important;
  width: 200px !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px;
  height: 40px;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .d-none {
  display: none !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .v-icon {
  margin: 0;
}

.check-out-cart .item-row .item-row__header {
  margin-left: 24px;
  font-size: 20px !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
</style>

<script>
import CheckOutSummaryItem from "@/components/check-out/CheckOutSummaryItem";
/*import CheckOutSummaryInfoBoxes from "@/components/check-out/CheckOutSummaryInfoBoxes";*/
import { mapGetters } from "vuex";

const tabsPrevious = {
  ostoskori: "ostoskori",
  yhteystiedot: "ostoskori",
  toimitustapa: "yhteystiedot",
  maksutapa: "toimitustapa",
  yhteenveto: "maksutapa",
};

export default {
  components: { CheckOutSummaryItem /*, CheckOutSummaryInfoBoxes*/ },
  data: () => ({
    isDeliveryTermsAccepted: false,
    dialog: false,
    purchaseComplete: false,
  }),
  created: function() {},
  methods: {
    acceptTerms() {
      (this.dialog = false), (this.isDeliveryTermsAccepted = true);
    },
    rejectTerms() {
      (this.dialog = false), (this.isDeliveryTermsAccepted = false);
    },
    startOrder() {
      const orderUrl = process.env.VUE_APP_BBSHOP_ENDPOINT + "/order/start";
      /*const checkoutUrl = process.env.VUE_APP_BBSHOP_ENDPOINT + "/klarna-checkout/create";*/
      const cartContents = {
        cartItems: [],
        data: {},
        deliveryAddress: {
          addressLine1: this.$store.state.deliveryAddress.streetAddress,
          addressLine2: "-",
          postalCode: this.$store.state.deliveryAddress.postalCode,
          city: this.$store.state.deliveryAddress.city,
          province: "-",
          country: "-",
        },
        contactInformation: {
          phoneNumber: this.$store.state.contactInfo.phoneNumber,
          emailAddress: this.$store.state.contactInfo.emailAddress,
          name: this.$store.state.contactInfo.name,
        },
        paymentMethod: "STORE",
      };

      this.$store.state.cart.shoppingCart.forEach((cartItem) => {
        if (cartItem.baseProduct) {
          cartContents.cartItems.push({
            "product-uuid": cartItem.baseProduct.uuid + ":" + cartItem.uuid,
            quantity: cartItem.count,
          });
        } else {
          cartContents.cartItems.push({
            "product-uuid": cartItem.uuid,
            quantity: cartItem.count,
          });
        }
      });

      if (this.$store.state.deliveryCost == 0) {
        cartContents.cartItems.push({
          "product-uuid": "nouto-myymalasta",
          quantity: 1,
        });
      } else if (this.$store.state.deliveryCost == 7.9) {
        cartContents.cartItems.push({
          "product-uuid": "toimitus-noutopisteeseen",
          quantity: 1,
        });
      } else if (this.$store.state.deliveryCost == 21) {
        cartContents.cartItems.push({
          "product-uuid": "matkahuolto-kotitoimitus",
          quantity: 1,
        });
      }
      if (this.$store.state.login.isLoggedIn) {
        cartContents.data.user_uuid = this.$store.state.login.uuid;
      }
      // this.axios.post(orderUrl, cartContents).then((response) => {
      this.axios.post(orderUrl, cartContents).then(() => {
        // console.log(response);

        let items = [];
        this.$store.state.cart.shoppingCart.forEach((item) => {
          let temp = {
            id: item.uuid,
            name: item.name,
            quantity: item.count,
            price: item.details.price.value,
          };
          items.push(temp);
        });

        this.$gtag.purchase({
          value: this.totalPrice,
          shipping: this.$store.state.deliveryCost,
          items: items,
        });
        this.purchaseComplete = true;
        this.$store.commit("emptyShoppingCart");
        this.$store.commit("resetOrderInfo");
      });
    },
  },
  computed: {
    totalPrice() {
      return (
        parseFloat(this.cartTotal) + this.$store.state.deliveryCost
      ).toFixed(2);
    },
    previousTab() {
      return tabsPrevious[this.$route.params.step];
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "100px";
        case "md":
          return "120px";
        default:
          return "150px";
      }
    },
    ...mapGetters(["cartTotal", "cartTotalItems"]),
  },
};
</script>
