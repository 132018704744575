<template>
  <div
    class="check-out d-flex flex-1 flex-column overflow-hidden"
    style="width:100%"
  >
    <CheckOutHeader v-if="$route.params.step != 'confirmation'" />

    <div class="d-flex mb-11 pb-10 checkout-main-view">
      <CheckOutCart v-if="$route.params.step == 'ostoskori'" />
      <CheckOutContactForm v-if="$route.params.step == 'yhteystiedot'" />
      <CheckOutDelivery v-if="$route.params.step == 'toimitustapa'" />
      <CheckOutPayment v-if="$route.params.step == 'maksutapa'" />
      <CheckOutSummary v-if="$route.params.step == 'yhteenveto'" />
      <CheckOutConfimation v-if="$route.params.step == 'confirmation'" />
      <v-divider
        vertical
        class="hidden-sm-and-down"
        v-if="
          !(
            $route.params.step == 'yhteenveto' ||
            $route.params.step == 'confirmation'
          )
        "
      ></v-divider>
      <CheckOutSmallSummary
        v-if="
          !(
            $route.params.step == 'yhteenveto' ||
            $route.params.step == 'confirmation'
          )
        "
      />
    </div>
    <CheckOutControls
      v-if="
        !(
          $route.params.step == 'yhteenveto' ||
          $route.params.step == 'confirmation'
        )
      "
    />
  </div>
</template>

<style>
.v-slide-group__content a {
  text-decoration: none;
}

.checkout-main-view {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
</style>

<script>
import CheckOutHeader from "@/components/check-out/CheckOutHeader";
import CheckOutSmallSummary from "@/components/check-out/CheckOutSmallSummary";
import CheckOutControls from "@/components/check-out/CheckOutControls";
import CheckOutPayment from "@/components/check-out/tabs/CheckOutPayment";
import CheckOutDelivery from "@/components/check-out/tabs/CheckOutDelivery";
import CheckOutCart from "@/components/check-out/tabs/CheckOutCart";
import CheckOutContactForm from "@/components/check-out/tabs/CheckOutContactForm";
import CheckOutSummary from "@/components/check-out/tabs/CheckOutSummary";
import CheckOutConfimation from "@/components/check-out/tabs/CheckOutConfimation";

export default {
  components: {
    CheckOutHeader,
    CheckOutSmallSummary,
    CheckOutControls,
    CheckOutCart,
    CheckOutContactForm,
    CheckOutDelivery,
    CheckOutPayment,
    CheckOutSummary,
    CheckOutConfimation,
  },
};
</script>
