<template>
  <div class="d-md-flex pb-8 mb-8">
    <v-card
      outlined
      tile
      flat
      min-height="100"
      class="d-flex flex-column justify-center pa-8 w-full mx-md-4"
      v-if="this.order.contactInformation != null"
    >
      <h4>Tilaajan tiedot</h4>
      <p v-if="this.order.contactInformation != null">
        {{ customerNameAndCompany }}
      </p>
      <span class="body-2" v-if="this.order.billingAddress != null">{{
        customerAddress
      }}</span>
      <span class="body-2" v-if="this.order.billingAddress != null">{{
        customerPostal
      }}</span>
      <br />
      <span class="body-2">{{ customerPhoneNumber }}</span>
      <span class="body-2">{{ customerEmail }}</span>
    </v-card>
    <v-card
      outlined
      tile
      flat
      min-height="100"
      class="d-flex flex-column justify-center w-full pa-8 mx-md-4"
    >
      <h4>Toimitusosoite</h4>
      <p>{{ deliveryMethod }}</p>
      <span class="body-2">{{ customerDeliveryAddress }}</span>
      <span class="body-2">{{ customerDeliveryPostal }}</span>
      <br />
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: false,
    },
    delivery: { type: Object, required: true },
  },
  computed: {
    customerNameAndCompany() {
      return this.order.contactInformation.companyName == undefined ||
        this.order.contactInformation.companyName == ""
        ? this.order.contactInformation.name
        : this.order.contactInformation.name +
            "/ yritys " +
            this.order.contactInformation.companyName;
    },
    customerAddress() {
      return this.order.billingAddress.addressLine2 == undefined ||
        this.order.billingAddress.addressLine2 == "" ||
        this.order.deliveryAddress.addressLine2 == "-" ||
        this.order.deliveryAddress.addressLine2 == "String"
        ? this.order.billingAddress.addressLine1
        : this.order.billingAddress.addressLine1 +
            ", " +
            this.order.billingAddress.addressLine2;
    },
    customerPostal() {
      return (
        this.order.billingAddress.postalCode +
        ", " +
        this.order.deliveryAddress.city
      );
    },
    customerPhoneNumber() {
      return this.order.contactInformation.phoneNumber;
    },
    customerEmail() {
      return this.order.contactInformation.emailAddress;
    },
    customerDeliveryAddress() {
      return this.order.deliveryAddress.addressLine2 == undefined ||
        this.order.deliveryAddress.addressLine2 == "" ||
        this.order.deliveryAddress.addressLine2 == "-" ||
        this.order.deliveryAddress.addressLine2 == "String"
        ? this.order.deliveryAddress.addressLine1
        : this.order.deliveryAddress.addressLine1 +
            ", " +
            this.order.deliveryAddress.addressLine2;
    },
    customerDeliveryPostal() {
      return (
        this.order.deliveryAddress.postalCode +
        ", " +
        this.order.deliveryAddress.city
      );
    },

    deliveryMethod() {
      if (this.delivery.uuid.includes("noutopiste")) {
        return "Toimitus noutopisteeseen";
      } else if (this.delivery.uuid.includes("kotitoimitus")) {
        return "Kotiinkuljetus";
      }
      return "Nouto myymälästä";
    },
  },
};
</script>

<style lang="css" scoped>
.total-price {
  font-weight: 700;
}

.w-full {
  width: 100%;
}
</style>
