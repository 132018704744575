<template>
<v-container
    class="px-0 wrapper"
    fluid
  >
  <div class="wrapper">
    <h2 class="text-light my-4">Asetukset</h2>
    <v-row>
        <v-col cols="12" md="6">
    <v-radio-group mandatory>
      <v-radio
        class="mb-8"
        label="En halua mitään viestejä sähköpostitse"
        value="2"
        @change="refuseEmail"
      ></v-radio>
      <v-radio
        label="Minulle saa lähettää sähköpostitse"
        value="1"
        @change="allowEmail"
      ></v-radio>
      <div v-if="!refuseAllEmail" class="ml-8">
        <v-checkbox
        v-model="emailSelection"
        label="Tietoa uusista tuotteista"
        value="Tuotteista"
        hide-details
        ></v-checkbox>
         <v-checkbox
        v-model="emailSelection"
        label="Tietoa tarjouksista ja kampanjoista"
        value="Tarjouksista"
        hide-details
        ></v-checkbox>
         <v-checkbox
        v-model="emailSelection"
        label="Muita viestejä"
        value="Muita"
        hide-details
        ></v-checkbox>
    </div>
    </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
    <v-radio-group mandatory>
        <v-radio
        class="mb-8"
        label="En halua mitään viestejä puhelimitse"
        value="refusePhone"
        @change="refusePhone"
      ></v-radio>
      <v-radio
        label="Minulle saa lähettää puhelimitse"
        value="allowPhone"
        @change="allowPhone"
      ></v-radio>
      <div v-if="!refuseAllPhone" class="ml-8">
        <v-checkbox
        v-model="phoneSelection"
        label="Tietoa uusista tuotteista"
        value="Puhelin tuotteista"
        hide-details
        ></v-checkbox>
         <v-checkbox
        v-model="phoneSelection"
        label="Tietoa tarjouksista ja kampanjoista"
        value="Puhelin tarjouksista"
        hide-details
        ></v-checkbox>
         <v-checkbox
        v-model="phoneSelection"
        label="Muita viestejä"
        value="Puhelin muita"
        hide-details
        ></v-checkbox>
    </div>
      
    </v-radio-group>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
            <v-btn color="primary" @click="submitPasswordForm" block depressed large rounded>Tallenna</v-btn>
        </v-col>
    </v-row>
    </div>
  </v-container>
</template>

<style scoped>
h2 {
    font-weight: 300;
}

.wrapper {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
}

</style>

<script>
export default{
    data() {
        return {
            refuseAllEmail: true,
            refuseAllPhone: true,
            emailSelection: ["Tuotteista"],
            phoneSelection: ["Puhelin tuotteista"]
        }
    },
    methods: {
        refuseEmail() {
            this.refuseAllEmail = true;
        },
        allowEmail() {
            this.refuseAllEmail = false;
        },
        refusePhone() {
            this.refuseAllPhone = true;
        },
        allowPhone() {
            this.refuseAllPhone = false;
        }
    }
}
</script>