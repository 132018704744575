<template>
  <div
    class="d-flex flex-column check-out-cart flex-grow-1 overflow-hidden pb-4"
  >
    <!--<div class="coupon-field d-flex pt-6 px-5">
      <v-text-field
        label="Kuponkikoodi (vapaaehtoinen)"
        outlined
        class="coupon-field__input flex-grow-1"
      >
      </v-text-field>
      <v-btn
        height="56"
        large
        depressed
        disabled
        class="coupon-field__btn flex-grow-0"
      >
        Lisää
      </v-btn>
    </div>-->

    <h2 class="pa-5 font-weight-light">Ostoskori</h2>
    <v-divider></v-divider>
    <CheckOutCartItem
      v-for="(item, i) in this.$store.state.cart.shoppingCart"
      :key="i"
      :item="item"
    />

    <!-- <ItemRow /> -->
    <RecentItems />
  </div>
</template>

<style>
.coupon-field__btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.coupon-field__input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.check-out-cart .v-slide-group__content .product-card {
  min-width: 100px !important;
  width: 200px !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px;
  height: 40px;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .d-none {
  display: none !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .v-icon {
  margin: 0;
}

.check-out-cart .item-row .item-row__header {
  margin-left: 24px;
  font-size: 20px !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
</style>

<script>
// import ItemRow from '@/components/ItemRow'
import RecentItems from "@/components/RecentItems";
import CheckOutCartItem from "@/components/check-out/CheckOutCartItem";

export default {
  // components: { ItemRow, RecentItems, CheckOutCartItem },
  components: { RecentItems, CheckOutCartItem },
};
</script>
