<template>
  <div v-if="this.formData !== ''">
    <v-form ref="mainForm">
      <v-container class="wrapper mb-16">
        <h2 class="my-4">Asiakastiedot</h2>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.firstName"
              label="Etunimi"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.lastName"
              label="Sukunimi"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.email"
              :rules="emailRules"
              label="Sähköpostiosoite"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0" v-if="formData.contactInformation">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.phoneNumber"
              label="Puhelinnumero"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <h2 class="my-4">Osoitetiedot</h2>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.addressLine1"
              label="Katuosoite"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.postalCode"
              label="Postinumero"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.city"
              label="Postitoimipaikka"
              :rules="mandatoryFieldRules"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.country"
              label="Maa"
              :rules="mandatoryFieldRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="12" md="12" class="py-0">
            <v-text-field
              outlined
              dense
              v-model="formData.address.additionalDetails"
              label="Ovikoodi tai muu lisätieto"
            ></v-text-field>
            <v-btn
              v-if="!showDeliveryFields"
              color="primary"
              class="mx-0 mb-4"
              outlined
              rounded
              large
              @click="toggleDeliveryFields"
            >
              <span class="d-none d-sm-inline mr-2">Lisää toimitusosoite</span>
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="showDeliveryFields">
          <h2 class="my-4">Toimitusosoite</h2>
          <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.addressLine1"
                label="Katuosoite"
                :rules="mandatoryFieldRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.postalCode"
                label="Postinumero"
                :rules="mandatoryFieldRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.city"
                label="Postitoimipaikka"
                :rules="mandatoryFieldRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.country"
                label="Maa"
                :rules="mandatoryFieldRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                outlined
                dense
                v-model="formData.deliveryAddress.additionalDetails"
                label="Ovikoodi tai muu lisätieto"
              ></v-text-field>
              <v-btn
                v-if="showDeliveryFields"
                color="primary"
                class="mx-0 mb-4"
                outlined
                rounded
                large
                @click="toggleDeliveryFields"
              >
                <span class="d-none d-sm-inline mr-2"
                  >Poista toimitusosoite</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <h2 class="my-4">Yritysasiakkaat</h2>
        <v-row v-if="formData.contactInformation">
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.companyName"
              label="Yrityksen nimi"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              dense
              v-model="formData.contactInformation.businessNumber"
              label="Y-tunnus"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn color="primary" @click="submitMainForm" block large rounded
              >Tallenna tietojen muutokset</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-divider></v-divider>
    <v-form ref="passwordForm">
      <v-container class="wrapper my-16">
        <h2 class="mb-4">Vaihda tilin salasana</h2>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              type="password"
              dense
              v-model="newPassword"
              :rules="passwordRules"
              label="Uusi salasana"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              outlined
              type="password"
              dense
              v-model="newPasswordAgain"
              :rules="passwordAgainRules"
              label="Vahvista uusi salasana"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn
              color="black"
              @click="submitPasswordForm"
              block
              depressed
              outlined
              large
              rounded
              >Vaihda salasana</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<style scoped>
h2 {
  font-weight: 300;
}

.wrapper {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
}
</style>

<script>
export default {
  data() {
    return {
      // firstName: "",
      // lastName: "",
      mandatoryFieldRules: [(v) => !!v || "Kenttä ei voi olla tyhjä."],
      email: "",
      emailRules: [
        (v) => !!v || "Sähköposti ei voi olla tyhjä.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      // phoneNumber: "",
      // streetAddress: "",
      // postalCode: "",
      // city: "",
      // country: "",
      // additionalInfo: "",
      // deliveryStreetAddress: "",
      // deliveryPostalCode: "",
      // deliveryCity: "",
      // deliveryCountry: "",
      // deliveryAdditionalInfo: "",
      // companyName: "",
      // yTunnus: "",
      newPassword: "",
      newPasswordAgain: "",
      passwordRules: [
        (v) => !!v || "Kenttä ei voi olla tyhjä.",
        (v) => v.length >= 8 || "Min 8 characters",
      ],
      passwordAgainRules: [
        (v) => !!v || "Kenttä ei voi olla tyhjä.",
        (v) => v == this.newPassword || "Passwords do not match",
      ],
      showDeliveryFields: false,
      baseUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/user/self",
      tempUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/user/",
      formData: {
        address: { addressLine1: "", postalCode: "", city: "", country: "" },
      },
      editableDataAddress: [
        "additionalDetails",
        "addressLine1",
        "addressLine2",
        "city",
        "country",
        "postalCode",
        "province",
      ],
      editableDataContactInformation: [
        "businessNumber",
        "companyName",
        "emailAddress",
        "name",
        "phoneNumber",
      ],
      editableDataDeliveryAddress: [
        "additionalDetails",
        "addressLine1",
        "addressLine2",
        "city",
        "country",
        "postalCode",
        "province",
      ],
      config: {
        headers: { Authorization: "Bearer " + this.$store.state.login.token },
      },
    };
  },
  mounted() {
    this.axios
      .get(this.baseUrl, this.config)
      .then((e) => {
        let data = e.data.item;
        // console.log(e.data.item);
        for (let x in data) {
          this.$set(this.formData, x, data[x]);
        }
        if (this.formData.address === null) {
          let address = {
            additionalDetails: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            country: "",
            postalCode: "",
            province: "",
          };
          this.$set(this.formData, "address", address);
        }
        if (this.formData.deliveryAddress === null) {
          let address = {
            additionalDetails: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            country: "",
            postalCode: "",
            province: "",
          };
          this.$set(this.formData, "deliveryAddress", address);
        }
        if (this.formData.contactInformation === null) {
          let information = {
            businessNumber: "",
            companyName: "",
            emailAddress: "",
            name: "",
            phoneNumber: "",
          };
          this.$set(this.formData, "contactInformation", information);
        }

        this.editableDataAddress.map((item) => {
          if (typeof this.formData.address[item] === "undefined") {
            this.$set(this.formData.address, item, "");
          }
        });

        this.editableDataDeliveryAddress.map((item) => {
          if (typeof this.formData.deliveryAddress[item] === "undefined") {
            this.$set(this.formData.deliveryAddress, item, "");
          }
        });

        this.editableDataContactInformation.map((item) => {
          if (typeof this.formData.contactInformation[item] === "undefined") {
            this.$set(this.formData.contactInformation, item, "");
          }
        });

        // console.log(this.formData);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    toggleDeliveryFields() {
      this.showDeliveryFields = !this.showDeliveryFields;
    },
    submitMainForm() {
      if (this.$refs.mainForm.validate()) {
        // let newFormData = this.formData;

        // Object.keys(newFormData).forEach(
        //   (obj) =>
        //     (newFormData[obj] == null ||
        //       obj.match(
        //         /created|customerNumber|lastModified|session|userLevel|uuid|verified|password|orders|email/
        //       )) &&
        //     delete newFormData[obj]
        // );

        this.$set(
          this.formData.contactInformation,
          "emailAddress",
          this.formData.email
        );
        this.$set(
          this.formData.contactInformation,
          "name",
          this.formData.firstName + " " + this.formData.lastName
        );

        // console.log(this.formData);

        this.axios
          .put(this.baseUrl, this.formData, this.config)
          // .then((e) => {
          .then(() => {
            // console.log(e);
            // console.log("success");
            this.$store.commit("setSnack", {
              text: "Tiedot tallennettu",
              message: "",
              color: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("setSnack", {
              text: "Virhe tietojen tallennuksessa",
              message: "",
              color: "error",
            });
          });
      }
    },
    submitPasswordForm() {
      if (
        this.$refs.passwordForm.validate() &&
        this.$refs.mainForm.validate()
      ) {
        let passwordFormData = this.formData;

        passwordFormData["password"] = this.newPassword;

        this.axios
          .put(this.baseUrl, passwordFormData, this.config)
          .then((e) => {
            console.log(e);
            this.$store.commit("setSnack", {
              text: "Salasana vaihdettu",
              message: "",
              color: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("setSnack", {
              text: "Virhe salasanan vaihdossa",
              message: "",
              color: "error",
            });
          });
      }
    },
  },
};
</script>