<template>
  <v-item>
    <v-card class="d-flex align-center flex-grow-1" flat tile v-on:click="setDeliveryMethod">
      <v-sheet
        
        width="40"
        height="40"
        class="d-flex justify-center flex-shrink-0 transparent"
      >
        <v-slide-x-transition>
          <v-icon v-if="this.$store.state.deliveryMethod == `Toimitus noutopisteeseen: ${this.point.streetAddress}, ${this.point.postalCode} ${this.point.city}`" color="success"> mdi-check </v-icon>
        </v-slide-x-transition>
      </v-sheet>
      <v-img
        src="@/assets/images/matkahuolto-logo.svg"
        class="my-4 mr-4 flex-grow-0"
        contain
        height="40"
        width="40"
      >
      </v-img>
      <div class="d-flex align-center" style="width: 100%">
        <div class="d-flex flex-column body-2 py-4" style="width: 70%">
          <div class="font-weight-bold">{{ `Matkahuolto, ${point.name}` }}</div>
          <div>
            {{ `${point.streetAddress}, ${point.postalCode} ${point.city}` }}
          </div>
        </div>
        <div class="font-weight-bold text-right mr-auto" style="width: 60px">
          7.90€
        </div>
      </div>
    </v-card>
  </v-item>
</template>

<script>
export default {
  props: ["point"],
  methods: {
    setDeliveryMethod: function() {
      this.$store.commit("setDeliveryMethod", {name: "Toimitus matkahuollon noutopisteeseen", cost: 7.9})
      this.$store.commit("setDeliveryAddress", {mhName: this.point.name, streetAddress: `${this.point.streetAddress}`, postalCode: `${this.point.postalCode}`, city: `${this.point.city}`, id: `${this.point.id}`})
      // console.log(this.$store.state.deliveryMethod)
      // console.log(this.$store.state.deliveryCost)
    }
  }
};
</script>
