<template>
  <div style="width: 100%" class="overflow-hidden flex-1 overflow-hidden">
    <Header />
    <AccountInformation v-if="$route.params.tab == 'yhteystiedot'" />
    <Orders v-if="$route.params.tab == 'tilaukset'" />
    <Settings v-if="$route.params.tab == 'asetukset'" />
    <OrderSummary v-if="$route.params.tab == 'tilauksen_id'" />
    <Footer />
  </div>
</template>

<style scoped>
</style>

<script>
import Header from "../components/user-page/Header";
import AccountInformation from "../components/user-page/tabs/AccountInformation";
import Orders from "../components/user-page/tabs/Orders";
import Settings from "../components/user-page/tabs/Settings";
import Footer from "../components/Footer";
import OrderSummary from "../components/user-page/OrderSummary";

export default {
  components: {
    Header,
    AccountInformation,
    Orders,
    Settings,
    Footer,
    OrderSummary,
  },
};
</script>
