<template>
  <div class="d-flex flex-column check-out-controls white" style="max-width: 100%;">
    <v-divider class="black"></v-divider>
    <div class="d-flex justify-center align-center pa-6 container">
      <router-link :to="previousTab" v-if="this.$route.params.step != 'ostoskori'">
        <v-btn outlined rounded>
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          <span class="d-none d-sm-block">Takaisin</span>
        </v-btn>
      </router-link>

      <div class="d-flex px-4 flex-wrap check-out-controls__total">
        <div class="d-flex align-baseline">
          <div class="product-card__currency mr-3" style="line-height:1">
            yht.
          </div>
          <h2 class="product-card__cost-value" style="line-height:1">
            {{ totalPrice }}€
          </h2>
        </div>
      </div>

        <v-btn v-if="this.$route.params.step != 'maksutapa'" :to="nextTab" :disabled="isDisabled" rounded color="primary" depressed>
          <span class="d-none d-sm-block">Seuraava</span>
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
    </div>
  </div>
</template>

<style scoped>
.check-out-controls {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

<script>
import { mapGetters } from "vuex";
/*
const tabsNext = {
  ostoskori: "yhteystiedot",
  yhteystiedot: "toimitustapa",
  toimitustapa: "maksutapa",
  maksutapa: "yhteenveto",
  yhteenveto: "yhteenveto",
};
const tabsPrevious = {
  ostoskori: "ostoskori",
  yhteystiedot: "ostoskori",
  toimitustapa: "yhteystiedot",
  maksutapa: "toimitustapa",
  yhteenveto: "maksutapa",
};
*/

const tabsNext = {
  ostoskori: "toimitustapa",
  toimitustapa: "maksutapa",
  maksutapa: "yhteenveto",
  yhteenveto: "yhteenveto",
};
const tabsPrevious = {
  ostoskori: "ostoskori",
  yhteystiedot: "ostoskori",
  toimitustapa: "ostoskori",
  maksutapa: "toimitustapa",
  yhteenveto: "maksutapa",
};

export default {
  computed: {
    totalPrice() {
      return (parseFloat(this.cartTotal) + this.$store.state.deliveryCost).toFixed(2)
    },
    
    isDisabled() {
        if(this.$route.params.step == 'toimitustapa') {
          if(this.$store.state.deliveryAddress.streetAddress.length > 0) {
            return false
          }
          return true
        } else if (this.$route.params.step == 'maksutapa'){
          if(this.$store.state.paymentMethod == "Maksu noudettaessa") {
          return false
          }
          return true
        }
        return false
      },
    nextTab() {
      return tabsNext[this.$route.params.step];
    },
    previousTab() {
      return tabsPrevious[this.$route.params.step];
    },
    ...mapGetters(["cartTotal", "cartTotalItems"]),
  },
};
</script>
