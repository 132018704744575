<template>
  <div
    class="d-flex flex-column check-out-cart flex-grow-1 overflow-hidden pb-4 container"
  >
    <h2 class="pa-5 font-weight-light">Yhteenveto</h2>
    <div class="d-flex mb-5">
      <router-link to="/asiakastili/tilaukset">
        <v-btn rounded color="primary" class="mr-5">
          <v-icon left size="16"> ${{ "Icon16ArrowLeft" }} </v-icon> Takaisin
          tilauksiin
        </v-btn>
      </router-link>
      <!-- <v-btn rounded color="primary" outlined>
        Lataa PDF
    </v-btn> -->
    </div>
    <v-divider></v-divider>
    <CheckOutSummaryItem
      v-for="(item, i) in this.orderProducts"
      :key="i"
      :item="item"
    />
    <v-divider></v-divider>
    <div class="d-md-flex padded justify-space-between">
      <div class="d-md-flex">
        <p class="font-weight-bold my-0">Toimitustapa</p>
        <p
          class="mx-md-4 my-0"
          v-if="this.delivery.uuid === 'toimitus-noutopisteeseen'"
        >
          Toimitus noutopisteeseen
        </p>
        <p
          class="mx-md-4 my-0"
          v-if="this.delivery.uuid === 'nouto-myymalasta'"
        >
          Nouto myymälästä
        </p>
        <p
          class="mx-md-4 my-0"
          v-if="this.delivery.uuid === 'matkahuolto-kotitoimitus'"
        >
          Kotiinkuljetus
        </p>
      </div>
      <span class="font-weight-bold"
        >{{ this.delivery.details.price.value.toFixed(2) }}€</span
      >
    </div>
    <v-divider></v-divider>
    <div class="d-md-flex padded flex-wrap justify-space-between">
      <div class="d-md-flex flex-wrap">
        <p class="font-weight-bold my-0">Maksutapa</p>
        <p
          class="mx-md-4 my-0"
          v-if="this.order.orderDetails.paymentMethod === 'STORE'"
        >
          Maksu myymälässä
        </p>
        <p class="mx-md-4 my-0" v-else>Klarna maksu</p>
      </div>
      <span class="font-weight-bold">0,00€</span>
    </div>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <v-divider></v-divider>
    <div class="d-flex justify-end pa-4 align-end">
      yht.
      <span class="text-h5 ml-2 font-weight-bold"
        >{{ this.order.orderDetails.toPay.toFixed(2) }}€</span
      >
    </div>

    <CheckOutSummaryInfoBoxes
      :order="this.order"
      :delivery="this.delivery"
      class="mt-8"
    />
  </div>
</template>

<script>
import CheckOutSummaryItem from "@/components/check-out/CheckOutSummaryItem";
import CheckOutSummaryInfoBoxes from "@/components/check-out/CheckOutSummaryInfoBoxes";
import { mapGetters } from "vuex";

const tabsPrevious = {
  ostoskori: "ostoskori",
  yhteystiedot: "ostoskori",
  toimitustapa: "yhteystiedot",
  maksutapa: "toimitustapa",
  yhteenveto: "maksutapa",
};

export default {
  components: { CheckOutSummaryItem, CheckOutSummaryInfoBoxes },
  data: () => ({
    isDeliveryTermsAccepted: false,
    dialog: false,
    order: null,
    delivery: null,
    orderProducts: [],
    //nouto-myymalasta, matkahuolto-kotitoimitus, toimitus-noutopisteeseen
  }),
  created() {
    let order = this.$store.state.orders.orders.items.filter(
      (i) => i.uuid === this.$route.params.uuid
    );
    this.order = order[0];
    let orderDelivery = this.order.orderDetails.items.filter(
      (i) =>
        i.product.uuid === "nouto-myymalasta" ||
        i.product.uuid === "matkahuolto-kotitoimitus" ||
        i.product.uuid === "toimitus-noutopisteeseen"
    );
    this.delivery = orderDelivery[0].product;
    console.log(this.order.orderDetails.items);
    for (let i in this.order.orderDetails.items) {
      let data = {
        assets: this.order.orderDetails.items[i].product.assets,
        count: this.order.orderDetails.items[i].quantity,
        name: this.order.orderDetails.items[i].product.name,
        price: this.order.orderDetails.items[i].product.details.price.value,
        sku: this.order.orderDetails.items[i].product.details.sku.value,
        uuid: this.order.orderDetails.items[i].product.uuid,
      };
      if (data.uuid !== this.delivery.uuid) {
        this.orderProducts.push(data);
      }
    }
  },
  methods: {
    acceptTerms() {
      (this.dialog = false), (this.isDeliveryTermsAccepted = true);
    },
    rejectTerms() {
      (this.dialog = false), (this.isDeliveryTermsAccepted = false);
    },
  },
  computed: {
    previousTab() {
      return tabsPrevious[this.$route.params.step];
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "100px";
        case "md":
          return "120px";
        default:
          return "150px";
      }
    },
    ...mapGetters(["cartTotal", "cartTotalItems"]),
  },
};
</script>

<style scoped>
.padded {
  padding: 16px 16px 16px 166px;
}

@media only screen and (max-width: 1263px) {
  .padded {
    padding: 16px 16px 16px 136px;
  }
}

@media only screen and (max-width: 959px) {
  .padded {
    padding: 16px 16px 16px 116px;
  }
}

@media only screen and (max-width: 599px) {
  .padded {
    padding: 16px 16px 16px 86px;
  }
}

.coupon-field__btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.coupon-field__input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.check-out-cart .v-slide-group__content .product-card {
  min-width: 100px !important;
  width: 200px !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 40px;
  height: 40px;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .d-none {
  display: none !important;
}

.check-out-cart
  .v-slide-group__content
  .product-card
  .product-card__add-cart-btn
  .v-icon {
  margin: 0;
}

.check-out-cart .item-row .item-row__header {
  margin-left: 24px;
  font-size: 20px !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
</style>
