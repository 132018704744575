<template>
  <div
    class="product-list flex-1 overflow-hidden px-md-6 pb-8"
    style="width: 100%"
  >
    <div class="list-actions py-4 px-3 d-flex">
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="toggle_exclusive" mandatory>
        <v-btn @click="makeList('grid')">
          <v-icon>${{ "Icon24Grid" }}</v-icon>
        </v-btn>

        <v-btn @click="makeList('list')">
          <v-icon>${{ "Icon24List" }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <ListFilters
      v-if="this.minValue !== 0 && this.maxValue !== 999999"
      @searchValues="searchValues"
      @sortResult="sortResult"
      :minValue="this.minValue"
      :maxValue="this.maxValue"
      @clearFilters="clearFilters"
    />

    <div class="list-container d-flex flex-wrap mx-auto" :class="list">
      <template v-for="item in items">
        <ProductCard :product="item" :key="item.uuid" />
      </template>
      <div v-if="loading">
        Ladataan tuotteita...
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <div v-else>
        <div v-if="items.length === 0">Kategoriassa ei tuotteita</div>
      </div>
    </div>
    <RecentItems />
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import RecentItems from "@/components/RecentItems";
import ListFilters from "@/components/ListFilters";
import { mapGetters } from "vuex";

export default {
  components: { ProductCard, RecentItems, ListFilters },
  data() {
    return {
      toggle_exclusive: undefined,
      list: "",
      items: "",
      loading: true,
      value: "",
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product",
      url2: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/category",
      uuid: "",
      minValue: 0,
      maxValue: 999999,
      itemsForFilter: "",
      categoryName: "",
      categoryImage: "",
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  metaInfo() {
    return {
      title:
        this.categoryName + " - Sisustus ja lahjatavaraliike Loviisan Aitta",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            this.categoryName + " Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          vmid: "ogtitle",
          property: "og:title",
          content: "Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          property: "og:site_name",
          content: "Loviisan Aitta",
        },
        {
          vmid: "ogdescription",
          property: "og:description",
          content: "Loviisan Aitta " + this.categoryName,
        },
        {
          property: "og:type",
          content: "profile",
        },
        {
          property: "og:url",
          content: "https://loviisanaitta.fi/" + this.$route.path,
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: this.baseurl + this.categoryImage + "/small.png",
        },
      ],
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    clearFilters() {
      this.getProducts();
    },
    makeList: function(option) {
      if (option === "grid") {
        this.list = "";
      } else if (option === "list") {
        this.list = "card-list";
      }
    },
    getProducts: async function() {
      if (this.$route.params.category) {
        let data = {
          from: 0,
          size: 10,
          query: {
            bool: {
              must: [
                {
                  term: {
                    "seoName.keyword": this.$route.params.category,
                  },
                },
              ],
            },
          },
        };

        await this.axios
          .post(this.url2, data)
          .then((res) => {
            if (res.data.items[0]) {
              // console.log(res.data.items);

              this.categoryName = res.data.items[0].name.fi;
              this.categoryImage = res.data.items[0].thumbnail;

              this.uuid = res.data.items[0].uuid;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.items = [];
            console.log(err);
          });

        let multiData = [];
        if (this.categoryUUID(this.$route.params.category).multi === true) {
          this.uuid = "multi";
          this.$store.state.cms.multiCategory[
            this.$route.params.category
          ].forEach((e) => {
            let data = {
              match_phrase: {
                categories: e,
              },
            };
            multiData.push(data);
          });
        }
        // console.log(multiData);
        if (this.uuid) {
          let data2 = {};
          if (this.uuid === "multi") {
            data2 = {
              from: 0,
              size: 1000,
              query: {
                bool: {
                  should: [multiData][0],
                },
              },
            };
          } else {
            data2 = {
              from: 0,
              size: 1000,
              query: {
                match_phrase: {
                  categories: this.uuid,
                },
              },
            };
          }
          // console.log(this.uuid);

          await this.axios.post(this.url, data2).then((res) => {
            this.items = res.data.items;
            this.sortResult("Uusin ensin");
            this.itemsForFilter = res.data.items;
            // console.log(this.items);

            this.minValue = Math.min.apply(
              Math,
              this.items.map((e) => {
                if (e.details.price) {
                  return e.details.price.value;
                } else return 1;
              })
            );
            this.maxValue = Math.max.apply(
              Math,
              this.items.map((e) => {
                if (e.details.price) {
                  return e.details.price.value;
                } else return 9999;
              })
            );

            this.loading = false;
          });
        } else {
          this.loading = false;
          this.items = [];
        }
      }
    },
    async searchValues(e) {
      let filterItems = this.itemsForFilter.filter(
        (i) => i.details.price.value >= e[0] && i.details.price.value <= e[1]
      );
      this.items = filterItems;

      // console.log(e);

      // let data = {
      //   from: 0,
      //   size: 100,
      //   query: {
      //     bool: {
      //       must: {
      //         query_string: {
      //           query: this.uuid,
      //           fields: ["categories"],
      //         },
      //       },
      //       filter: {
      //         range: {
      //           "details.price.value": {
      //             from: e[0],
      //             to: e[1],
      //           },
      //         },
      //       },
      //     },
      //   },
      // };
      // // console.log(data);
      // await this.axios
      //   .post(this.url, data)
      //   .then((response) => {
      //     // console.log(response.data.items);
      //     this.items = response.data.items;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
    sortResult(e) {
      // console.log(e);
      if (e === "Halvin ensin") {
        this.items.sort(
          (a, b) => a.details.price.value - b.details.price.value
        );
      } else if (e === "Kallein ensin") {
        this.items.sort(
          (a, b) => b.details.price.value - a.details.price.value
        );
      } else if (e === "Uusin ensin") {
        this.items.sort((a, b) => {
          let c = a.created.split(" ");
          let d = b.created.split(" ");
          return new Date(d[0]) - new Date(c[0]);
        });
      } else if (e === "Vanhin ensin") {
        this.items.sort((a, b) => {
          let c = a.created.split(" ");
          let d = b.created.split(" ");
          return new Date(c[0]) - new Date(d[0]);
        });
      }
      // this.items.map((e) => {
      //   console.log(e.details.price.value + ", ");
      // });
    },
  },

  computed: {
    ...mapGetters(["categoryUUID"]),
  },
  watch: {
    "$store.state.cms.categoryData": function() {
      // console.log("category loaded");
      this.getProducts();
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .list-header {
    flex-direction: column;
  }

  .list-actions {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
