<template>
  <div
    class="
      d-flex
      flex-column
      check-out-payment
      flex-grow-1
      overflow-hidden
      pb-4
      mx-auto
    "
    style="max-width: 700px"
  >
    <h2 class="pa-5 font-weight-light">Maksuvaihtoehdot</h2>
    <v-item-group mandatory>
      <!-- Pickup payment option -->

      <div>
        <v-item>
          <div class="d-flex flex-column">
            <v-card
              :disabled="!this.$store.state.deliveryMethod.includes('Nouto')"
              flat
              tile
              class="d-flex align-center"
              v-on:click="selectPaymentMethod('Maksu noudettaessa')"
            >
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                <v-slide-x-transition>
                  <v-icon
                    v-if="this.selectedPaymentMethod == 'Maksu noudettaessa'"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </v-slide-x-transition>
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/pickup-pay.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div class="font-weight-bold">Maksa noudattaessa</div>
                  <div>Käteinen tai korttimaksu</div>
                </div>
                <v-spacer></v-spacer>
              </div>
            </v-card>
          </div>
        </v-item>
        <div v-if="this.selectedPaymentMethod == 'Maksu noudettaessa'">
          <v-card flat class="px-8">
            <v-card-title>Yhteystiedot noutoa varten</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="pickupName"
                      :rules="nameRules"
                      label="Nimi"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <vue-tel-input-vuetify
                      v-model="pickupPhone"
                      @validate="validatePhone"
                      label="Syötä puhelinnumero"
                      placeholder="Syötä puhelinnumero"
                      defaultCountry="FI"
                    ></vue-tel-input-vuetify>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="pickupEmail"
                      :rules="emailRules"
                      label="Sähköpostiosoite"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      primary
                      depressed
                      rounded
                      color="primary"
                      @click="setContactInfo"
                      :disabled="!valid || !phoneIsValid"
                    >
                      Siirry tilauksen yhteenvetoon
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <br />
          </v-card>
        </div>
      </div>
      <!-- Klarna option -->
      <div>
        <v-divider></v-divider>
        <v-item>
          <div class="d-flex flex-column">
            <v-card
              class="d-flex align-center"
              flat
              tile
              v-on:click="selectPaymentMethod('Klarna')"
              @click="createOrder()"
            >
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                <v-slide-x-transition>
                  <v-icon
                    v-if="
                      this.selectedPaymentMethod &&
                      this.selectedPaymentMethod == 'Klarna'
                    "
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </v-slide-x-transition>
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/klarna-pay.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div class="font-weight-bold">Klarna</div>
                  <div>Lasku- ja osamaksuvaihtoehdot</div>
                </div>
                <v-spacer></v-spacer>
                <!-- <v-icon class="chevron-up-icon ma-4">mdi-chevron-up</v-icon> -->
                <v-icon class="chevron-up-icon ma-4">{{ this.icon }}</v-icon>
              </div>
            </v-card>
            <v-expand-transition>
              <div
                v-show="
                  this.selectedPaymentMethod &&
                  this.selectedPaymentMethod == 'Klarna'
                "
              >
                <div id="my-checkout-container"></div>
                <!--
              <div v-show="active" style="display: flex;">
                
                
                <div class="d-flex flex-column px-10 pt-2 pb-6">
                  <h4 class="mb-3">Haluatko maksaa?</h4>
                  <v-item-group class="d-flex flex-wrap align-center">
                    <v-item v-slot="{ active, toggle }">
                      <v-btn
                        outlined
                        rounded
                        @click="toggle"
                        :color="active ? 'primary' : ''"
                      >
                        <v-icon v-if="active" class="mr-1">
                          mdi-check
                        </v-icon>
                        Kerrella
                      </v-btn>
                    </v-item>
                    <div class="body-1 mx-4">tai</div>
                    <v-item v-slot="{ active, toggle }">
                      <v-btn
                        outlined
                        rounded
                        @click="toggle"
                        :color="active ? 'primary' : ''"
                      >
                        <v-icon v-if="active" class="mr-1">
                          mdi-check
                        </v-icon>
                        Osissa
                      </v-btn>
                    </v-item>
                  </v-item-group>
                  <v-expand-transition>
                    <div v-show="klarnaOnePaymentExpand">
                      <p class="pa-5" style="width: 100%;">
                        Klarna maksa kerralla
                      </p>
                    </div>
                  </v-expand-transition>
                  <v-expand-transition>
                    <div
                      v-show="
                        klarnaPartPaymentExpand == !klarnaPartPaymentExpand
                      "
                    >
                      <p class="pa-5" style="width: 100%;">
                        Klarna maksa osissa
                      </p>
                    </div>
                  </v-expand-transition>
                </div>
                -->
              </div>
            </v-expand-transition>
          </div>
        </v-item>
      </div>

      <!-- Bank options -->
      <!--
      <div>
        <v-divider></v-divider>
        <v-item v-slot="{ active, toggle }">
          <div class="d-flex flex-column">
            <v-card class="d-flex align-center" flat tile @click="toggle">
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                <v-slide-x-transition>
                  <v-icon v-if="active" color="success">
                    mdi-check
                  </v-icon>
                </v-slide-x-transition>
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/online-bank-pay.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div class="font-weight-bold">Verkkopankki</div>
                  <div>Pankkien verkkomaksuvaihtoehdot</div>
                </div>
                <v-spacer></v-spacer>
                <v-img
                  src="@/assets/images/bank-logos.svg"
                  class="mx-4 flex-grow-0 hidden-sm-and-down"
                  contain
                  height="40"
                ></v-img>
                <v-icon class="chevron-up-icon ma-4">mdi-chevron-up</v-icon>
              </div>
            </v-card>

            <v-expand-transition>
              <div v-show="active == !expand">
                <div
                  class="d-flex flex-wrap mx-auto pa-4"
                  style="max-width:732px"
                >
                  <div v-for="n in 10" :key="n">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        class="pa-2 ma-2"
                        :class="{ 'grey lighten-3': hover }"
                        width="124"
                        height="124"
                        flat
                        outlined
                        v-ripple
                      >
                        <v-img
                          src="@/assets/images/bank-logos/op-logo.svg"
                          class="d-flex-grow-1 mx-auto my-3"
                          contain
                          height="56"
                          width="80%"
                        >
                        </v-img>
                        <div class="d-flex-shrink-0 body-2 text-center">
                          Osuuspankki
                        </div>
                      </v-card>
                    </v-hover>
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </v-item>
      </div>
      -->

      <!-- Credit card options -->
      <!--
      <div>
        <v-divider></v-divider>
        <v-item v-slot="{ active, toggle }">
          <div class="d-flex flex-column">
            <v-card class="d-flex align-center" flat tile @click="toggle">
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                <v-slide-x-transition>
                  <v-icon v-if="active" color="success">
                    mdi-check
                  </v-icon>
                </v-slide-x-transition>
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/credit-card-pay.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div class="font-weight-bold">Maksukortti</div>
                  <div>Maksukorttivaihtoehdot</div>
                </div>
                <v-spacer></v-spacer>
                <div class="px-4 hidden-sm-and-down" style="display: flex;">
                  <v-img
                    src="@/assets/images/Visa-small.svg"
                    class="mx-1 flex-grow-0"
                    height="32"
                  ></v-img>
                  <v-img
                    src="@/assets/images/Mastercard-small.svg"
                    class="mx-1 flex-grow-0"
                    height="32"
                  ></v-img>
                  <v-img
                    src="@/assets/images/Amex-small.svg"
                    class="mx-1 flex-grow-0"
                    height="32"
                  ></v-img>
                </div>
                <v-icon class="chevron-up-icon ma-4">mdi-chevron-up</v-icon>
              </div>
            </v-card>

            <v-expand-transition>
              <div v-show="active" class="px-7">
                <div class="pa-4">test</div>
                <div class="pa-4">test</div>
                <div class="pa-4">test</div>
                <div class="pa-4">test</div>
              </div>
            </v-expand-transition>
          </div>
        </v-item>
      </div>
      -->
    </v-item-group>
    <v-divider></v-divider>
  </div>
</template>

<style scoped>
.v-item--active .chevron-up-icon {
  transform: rotate(-180deg);
}
</style>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

export default {
  components: {
    VueTelInputVuetify,
  },
  data: () => ({
    expand: false,
    klarnaOnePaymentExpand: false,
    klarnaPartPaymentExpand: false,
    disablePickupPayment: false,
    selectedPaymentMethod: null,
    pickupName: "",
    pickupEmail: "",
    pickupPhone: "",
    phoneIsValid: false,
    valid: false,
    orderCreated: false,
    createdOrderUuid: null,
    nameRules: [(v) => !!v || "Nimi on pakollinen"],
    phoneRules: [(v) => !!v || "Puhelinnumero on pakollinen"],
    emailRules: [
      (v) => !!v || "Sähköpostiosoite on pakollinen",
      (v) => /.+@.+\..+/.test(v) || "Tarkista sähköpostiosoite",
    ],
    icon: "mdi-chevron-down",
  }),
  created: function () {
    if (this.$store.state.deliveryMethod == "Valitse toimitustapa") {
      this.$router.push("toimitustapa");
    } else if (!this.$store.state.deliveryMethod.includes("Nouto")) {
      this.disablePickupPayment = true;
    }
  },
  mounted() {
    this.selectPaymentMethod("Klarna");
    this.createOrder();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validatePhone({ isValid }) {
      this.phoneIsValid = isValid;
    },
    setContactInfo: function () {
      this.$store.commit("setContactInfo", {
        name: this.pickupName,
        email: this.pickupEmail,
        phone: this.pickupPhone,
      });
      this.$router.push("/kassa/yhteenveto");
    },
    selectPaymentMethod: function (method) {
      this.selectedPaymentMethod = method;
      this.$store.commit("setPaymentMethod", method);
      if (method === "Klarna") {
        this.icon = "mdi-chevron-up";
      } else {
        this.icon = "mdi-chevron-down";
      }
    },
    klarnaOnePayment() {
      this.klarnaOnePaymentExpand == !this.klarnaOnePaymentExpand;
    },
    createOrder() {
      if (!this.orderCreated) {
        this.orderCreated = true;
        console.log("Creating order...");
        const orderUrl = process.env.VUE_APP_BBSHOP_ENDPOINT + "/order/start";
        const checkoutUrl =
          process.env.VUE_APP_BBSHOP_ENDPOINT + "/klarna-checkout/create";

        const cartContents = {
          cartItems: [],
          data: {},
          deliveryAddress: {
            addressLine1: this.$store.state.deliveryAddress.streetAddress,
            addressLine2: "-",
            postalCode: this.$store.state.deliveryAddress.postalCode,
            city: this.$store.state.deliveryAddress.city,
            province: "-",
            country: "-",
            additionalDetails: this.$store.state.deliveryAddress.id,
          },
          paymentMethod: "KLARNA",
        };

        this.$store.state.cart.shoppingCart.forEach((cartItem) => {
          if (cartItem.baseProduct) {
            cartContents.cartItems.push({
              "product-uuid": cartItem.baseProduct.uuid + ":" + cartItem.uuid,
              quantity: cartItem.count,
            });
          } else {
            cartContents.cartItems.push({
              "product-uuid": cartItem.uuid,
              quantity: cartItem.count,
            });
          }
        });

        if (this.$store.state.login.isLoggedIn) {
          cartContents.data.user_uuid = this.$store.state.login.uuid;
        }

        if (this.$store.state.deliveryCost == 0) {
          cartContents.cartItems.push({
            "product-uuid": "nouto-myymalasta",
            quantity: 1,
          });
        } else if (this.$store.state.deliveryCost == 7.9) {
          cartContents.cartItems.push({
            "product-uuid": "toimitus-noutopisteeseen",
            quantity: 1,
          });
        } else if (this.$store.state.deliveryCost == 21) {
          cartContents.cartItems.push({
            "product-uuid": "matkahuolto-kotitoimitus",
            quantity: 1,
          });
        }

        this.axios.post(orderUrl, cartContents).then((response) => {
          this.createdOrderUuid = response.data.item.uuid;
          this.axios
            .post(checkoutUrl, { orderUuId: response.data.item.uuid })
            .then((res) => {
              const htmlSnippet = res.data.item;
              const checkoutContainer = document.getElementById(
                "my-checkout-container"
              );
              checkoutContainer.innerHTML = htmlSnippet;
              const scriptsTags =
                checkoutContainer.getElementsByTagName("script");
              // This is necessary otherwise the scripts tags are not going to be evaluated
              for (const scriptsTag of scriptsTags) {
                const parentNode = scriptsTag.parentNode;
                const newScriptTag = document.createElement("script");
                newScriptTag.type = "text/javascript";
                newScriptTag.text = scriptsTag.text;
                parentNode.removeChild(scriptsTag);
                parentNode.appendChild(newScriptTag);
              }
            })
            .catch((err) => console.log(err));
        });
      } else {
        console.log("Order already created. Continue");
        const checkoutUrl =
          process.env.VUE_APP_BBSHOP_ENDPOINT + "/klarna-checkout/create";
        this.axios
          .post(checkoutUrl, { orderUuId: this.createdOrderUuid })
          .then((res) => {
            const htmlSnippet = res.data.item;
            const checkoutContainer = document.getElementById(
              "my-checkout-container"
            );
            checkoutContainer.innerHTML = htmlSnippet;
            const scriptsTags =
              checkoutContainer.getElementsByTagName("script");
            // This is necessary otherwise the scripts tags are not going to be evaluated
            for (const scriptsTag of scriptsTags) {
              const parentNode = scriptsTag.parentNode;
              const newScriptTag = document.createElement("script");
              newScriptTag.type = "text/javascript";
              newScriptTag.text = scriptsTag.text;
              parentNode.removeChild(scriptsTag);
              parentNode.appendChild(newScriptTag);
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
