<template>
  <div class="d-flex flex-column check-out-cart__item">
    <v-card tile flat min-height="100" class="d-flex">
      <v-img
        v-if="item.thumbnail"
        contain
        :width="width"
        aspect-ratio="1"
        class="product-card__img flex-grow-0"
        :src="baseurl + item.thumbnail + '/small.png'"
      ></v-img>
      <v-img
        v-else-if="item.assets"
        contain
        :width="width"
        aspect-ratio="1"
        class="product-card__img flex-grow-0"
        :src="baseurl + item.assets[0] + '/small.png'"
      ></v-img>
      <v-img
        v-else
        contain
        :width="width"
        aspect-ratio="1"
        class="product-card__img flex-grow-0"
        src="@/assets/images/no-img.jpg"
      >
      </v-img>
      <div class="d-sm-flex flex-column justify-center flex-grow-1 pa-4">
        <div class="d-sm-flex justify-space-between">
          <div class="body-2 d-flex flex-column">
            <h4>{{ item.name }}</h4>
            <span v-if="item.discount"
              >Alennus {{ item.discount.percent }} %</span
            >
            <!-- <p class="mb-8 mb-sm-0">Koko lisätietoja yms.</p> -->
          </div>
          <div class="body-1 d-sm-flex flex-column text-sm-right p-relative">
            <div class="d-sm-flex">
              <!--<span class="mr-6 caption d-none d-sm-block"
                >(Norm. hinta 9999€)</span
              >-->
              <span class="item-price body-2 pr-4" v-if="item.discount">
                <del>
                  {{ realPrice }}
                </del>
              </span>
              <span class="item-price body-2">{{ priceLine }}</span>
            </div>
            <p class="ma-0 total-price">
              <span class="body-2">yht.</span> <strong>{{ priceTotal }}</strong>
            </p>
          </div>
        </div>
      </div>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  mounted() {
    console.log(this.item);
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "100px";
        case "md":
          return "120px";
        default:
          return "150px";
      }
    },
    priceLine() {
      // console.log(this.item);
      if (this.item.discount) {
        let percent = this.item.discount.percent / 100;
        let discountPrice = this.item.price * (1.0 - percent);
        return this.item.count + " x " + discountPrice.toFixed(2) + "/kpl";
      } else {
        return `${this.item.count} x ${this.item.price.toFixed(2)}/kpl`;
      }
    },
    realPrice() {
      return this.item.price.toFixed(2);
    },
    priceTotal() {
      if (this.item.discount) {
        let percent = this.item.discount.percent / 100;
        let price = this.item.price * (1.0 - percent);
        return (this.item.count * price).toFixed(2);
      } else {
        return (this.item.count * this.item.price).toFixed(2);
      }
    },
  },
};
</script>

<style lang="css" scoped>
@media only screen and (max-width: 600px) {
  .item-price {
    position: absolute;
    left: 86px;
    bottom: 15px;
  }

  .total-price {
    position: absolute;
    right: 15px;
    bottom: 15px;
    line-height: 14px;
  }
}
</style>
