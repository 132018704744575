<template>
<div class="wrapper">
    <h2 class="mt-8 mb-12">Tilaukset</h2>
    <OrderCard />
    </div>
</template>
<style scoped>
h2 {
    font-weight: 300;
}

.wrapper {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
}

</style>

<script>
import OrderCard from '../OrderCard'
export default{components: { OrderCard}}
</script>