<template>
  <div
    class="
      chek-out__small-summary
      flex-grow-0 flex-shrink-0
      pa-6
      hidden-sm-and-down
    "
    style="width: 320px"
  >
    <!--
    <div
      class="d-flex flex-column py-2"
      v-if="
        $route.params.step == 'toimitustapa' ||
          $route.params.step == 'maksutapa' ||
          $route.params.step == 'yhteenveto'
      "
    >
      <div class="d-flex justify-space-between align-center py-2">
        <h4>Tilaajan tiedot</h4>
        <router-link to="/kassa/yhteystiedot">
          <v-btn text small>
            <v-icon left>mdi-pencil</v-icon>
            Muokkaa
          </v-btn>
        </router-link>
      </div>
      <div class="body-2 font-weight-bold">{{ customerNameAndCompany }}</div>
      <div class="body-2">{{ customerAddress }}</div>
      <div class="body-2">{{ customerPostal }}</div>
      <br />
      <div class="body-2">{{ customerPhoneNumber }}</div>
      <div class="body-2">{{ customerEmail }}</div>

      <v-divider class="mt-4"></v-divider>
    </div>

    <div
      class="d-flex flex-column py-2"
      v-if="
        $route.params.step == 'toimitustapa' ||
          $route.params.step == 'maksutapa' ||
          $route.params.step == 'yhteenveto'
      "
    >
      <div class="d-flex justify-space-between align-center py-2">
        <h4>Toimitusosoite</h4>
        <router-link to="/kassa/yhteystiedot">
          <v-btn text small>
            <v-icon left>mdi-pencil</v-icon>
            Muokkaa
          </v-btn>
        </router-link>
      </div>
      <div class="body-2 font-weight-bold">{{ customerNameAndCompany }}</div>
      <div class="body-2">{{ customerDeliveryAddress }}</div>
      <div class="body-2">{{ customerDeliveryPostal }}</div>

      <v-divider class="mt-4"></v-divider>
    </div>
    -->

    <div
      class="d-flex flex-column py-2"
      v-if="
        $route.params.step == 'toimitustapa' ||
        $route.params.step == 'maksutapa'
      "
    >
      <div class="d-flex justify-space-between align-center py-2">
        <h4>Toimitustapa</h4>
        <router-link
          to="/kassa/toimitustapa"
          v-if="
            $route.params.step == 'maksutapa' ||
            $route.params.step == 'yhteenveto'
          "
        >
          <v-btn text small>
            <v-icon left>mdi-pencil</v-icon>
            Muokkaa
          </v-btn>
        </router-link>
      </div>
      <div class="body-2">
        <span class="font-weight-bold">{{
          `${this.$store.state.deliveryMethod}`
        }}</span>
        <br />
        <div v-if="this.$store.state.deliveryAddress.streetAddress.length > 0">
          <span v-if="this.$store.state.deliveryAddress.mhName.length > 0"
            >{{ `${this.$store.state.deliveryAddress.mhName}` }} <br
          /></span>
          {{ `${this.$store.state.deliveryAddress.streetAddress}` }} <br />
          {{ `${this.$store.state.deliveryAddress.postalCode}` }},
          {{ `${this.$store.state.deliveryAddress.city}` }}
        </div>
      </div>
      <v-divider class="mt-4"></v-divider>
    </div>

    <div class="d-flex flex-column py-2">
      <h4 class="py-2">Yhteensä</h4>
      <div class="d-flex justify-space-between align-center body-2">
        <span>Tuotteet yhteensä ({{ this.cartTotalItems }}kpl)</span>
        <span>{{ this.cartTotal }}€</span>
      </div>
      <div
        class="d-flex justify-space-between align-center body-2"
        v-if="
          $route.params.step == 'toimitustapa' ||
          $route.params.step == 'maksutapa'
        "
      >
        <span>Toimituskulut</span>
        <span v-if="this.$store.state.deliveryCost !== 0">
          {{ this.$store.state.deliveryCost.toFixed(2) }}€
        </span>
        <span v-else> {{ this.$store.state.deliveryCost }}€ </span>
      </div>
      <v-divider class="mt-4 mb-1 black"></v-divider>
      <h4 class="ml-auto py-2">{{ totalPrice }}€</h4>
    </div>
  </div>
</template>

<style scoped>
.chek-out__small-summary .body-2 {
  font-size: 0.8rem !important;
  line-height: 1.5rem;
}

a {
  text-decoration: none;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    totalPrice() {
      return (
        parseFloat(this.cartTotal) + this.$store.state.deliveryCost
      ).toFixed(2);
    },
    customerNameAndCompany() {
      return this.$store.state.customercompany == undefined ||
        this.$store.state.customercompany == ""
        ? `${this.$store.state.customername}`
        : `${this.$store.state.customername} / yritys ${this.$store.state.customercompany}`;
    },
    customerAddress() {
      return this.$store.state.customeraddr2 == undefined ||
        this.$store.state.customeraddr2 == ""
        ? `${this.$store.state.customeraddr}`
        : `${this.$store.state.customeraddr}, ${this.$store.state.customeraddr2}`;
    },
    customerPostal() {
      return `${this.$store.state.customerpostalcode} ${this.$store.state.customerpostaloffice}`;
    },
    customerPhoneNumber() {
      return `${this.$store.state.customerphonenumber}`;
    },
    customerEmail() {
      return `${this.$store.state.customeremail}`;
    },
    customerDeliveryAddress() {
      return this.$store.state.customerdeliveryaddr2 == undefined ||
        this.$store.state.customerdeliveryaddr2 == ""
        ? `${this.$store.state.customerdeliveryaddr}`
        : `${this.$store.state.customerdeliveryaddr}, ${this.$store.state.customerdeliveryaddr2}`;
    },
    customerDeliveryPostal() {
      return `${this.$store.state.customerdeliverypostalcode} ${this.$store.state.customerdeliverypostaloffice}`;
    },
    ...mapGetters(["cartTotal", "cartTotalItems"]),
  },
};
</script>
