var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    chek-out__small-summary\n    flex-grow-0 flex-shrink-0\n    pa-6\n    hidden-sm-and-down\n  ",staticStyle:{"width":"320px"}},[(
      _vm.$route.params.step == 'toimitustapa' ||
      _vm.$route.params.step == 'maksutapa'
    )?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"d-flex justify-space-between align-center py-2"},[_c('h4',[_vm._v("Toimitustapa")]),(
          _vm.$route.params.step == 'maksutapa' ||
          _vm.$route.params.step == 'yhteenveto'
        )?_c('router-link',{attrs:{"to":"/kassa/toimitustapa"}},[_c('v-btn',{attrs:{"text":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Muokkaa ")],1)],1):_vm._e()],1),_c('div',{staticClass:"body-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(("" + (this.$store.state.deliveryMethod))))]),_c('br'),(this.$store.state.deliveryAddress.streetAddress.length > 0)?_c('div',[(this.$store.state.deliveryAddress.mhName.length > 0)?_c('span',[_vm._v(_vm._s(("" + (this.$store.state.deliveryAddress.mhName)))+" "),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(("" + (this.$store.state.deliveryAddress.streetAddress)))+" "),_c('br'),_vm._v(" "+_vm._s(("" + (this.$store.state.deliveryAddress.postalCode)))+", "+_vm._s(("" + (this.$store.state.deliveryAddress.city)))+" ")]):_vm._e()]),_c('v-divider',{staticClass:"mt-4"})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-2"},[_c('h4',{staticClass:"py-2"},[_vm._v("Yhteensä")]),_c('div',{staticClass:"d-flex justify-space-between align-center body-2"},[_c('span',[_vm._v("Tuotteet yhteensä ("+_vm._s(this.cartTotalItems)+"kpl)")]),_c('span',[_vm._v(_vm._s(this.cartTotal)+"€")])]),(
        _vm.$route.params.step == 'toimitustapa' ||
        _vm.$route.params.step == 'maksutapa'
      )?_c('div',{staticClass:"d-flex justify-space-between align-center body-2"},[_c('span',[_vm._v("Toimituskulut")]),(this.$store.state.deliveryCost !== 0)?_c('span',[_vm._v(" "+_vm._s(this.$store.state.deliveryCost.toFixed(2))+"€ ")]):_c('span',[_vm._v(" "+_vm._s(this.$store.state.deliveryCost)+"€ ")])]):_vm._e(),_c('v-divider',{staticClass:"mt-4 mb-1 black"}),_c('h4',{staticClass:"ml-auto py-2"},[_vm._v(_vm._s(_vm.totalPrice)+"€")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }