<template>
  <div class="d-flex flex-column check-out-cart__item p-relative">
    <v-card
      tile
      flat
      min-height="100"
      class="d-sm-flex flex-grow-0 flex-shrink-0 transparent"
    >
      <router-link
        :to="{
          name: 'ProductPage',
          params: { sku: realSku(item), name: generateSlug(item.name) },
        }"
      >
        <v-img
          v-if="item.thumbnail"
          contain
          :width="width"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 flex-shrink-0 mx-auto"
          :src="baseurl + item.thumbnail + '/small.png'"
        ></v-img>
        <v-img
          v-else-if="item.assets"
          contain
          :width="width"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 flex-shrink-0 mx-auto"
          :src="baseurl + item.assets[0] + '/small.png'"
        ></v-img>
        <v-img
          v-else
          contain
          :width="width"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 mx-auto"
          src="@/assets/images/no-img.jpg"
        >
        </v-img>
      </router-link>
      <div
        class="
          d-flex
          flex-column
          justify-center
          flex-grow-1
          pa-4
          overflow-hidden
          check-out-cart__item-content
        "
      >
        <router-link
          :to="{
            name: 'ProductPage',
            params: { sku: realSku(item), name: generateSlug(item.name) },
          }"
        >
          <h4>{{ item.name }}</h4>
        </router-link>
        <div class="d-md-flex align-center">
          <div
            class="d-flex flex-column mr-2 mb-4 overflow-hidden justify-center"
          >
            <div style="display: inline-block">
              <span v-if="item.discount">
                Alennus {{ item.discount.percent }} %
              </span>
              <span
                v-for="(variant, i) in item.variantProduct"
                :key="i + variant.name"
              >
                <span>{{ variant.name }}</span>
                <span>:</span>
                <span>
                  {{ variant.value }}
                </span>
                <span v-if="variant.name === 'Paino'">kg</span>
                <span v-if="item.variantProduct.length > i + 1">, </span>
              </span>
            </div>
            <div class="body-2 d-flex flex-wrap mt-4 mt-sm-0">
              <span class="mr-3">
                {{ priceLine }}
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>

          <div class="d-sm-flex align-center flex-shrink-0">
            <div class="d-flex">
              <StepperInput :item="item" class="py-2 pa-md-0" />
              <div
                class="
                  d-flex
                  flex-wrap
                  align-baseline
                  mr-sm-4
                  mx-md-4
                  my-auto
                  py-2
                  pa-md-0
                  total-price
                "
              >
                <div class="product-card__currency mr-3" style="line-height: 1">
                  yht.
                </div>
                <div v-if="item.discount" class="d-flex">
                  <h3
                    class="product-card__cost-value px-4"
                    style="
                      line-height: 1;
                      text-decoration: line-through;
                      text-decoration-thickness: 2px;
                    "
                  >
                    {{ priceTotal }}€
                  </h3>

                  <h3 class="product-card__cost-value" style="line-height: 1">
                    {{ discountPrice }}€
                  </h3>
                </div>
                <h3
                  v-else
                  class="product-card__cost-value"
                  style="line-height: 1"
                >
                  {{ priceTotal }}€
                </h3>
              </div>
            </div>
            <v-btn
              outlined
              rounded
              class="remove-button"
              @click="removeFromCart(item.uuid)"
            >
              <v-icon left> mdi-close </v-icon>
              Poista
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<style lang="css" scoped>
@media only screen and (max-width: 599px) {
  .remove-button {
    position: absolute;
    top: 20px;
    right: 15px;
  }

  .total-price {
    position: absolute;
    bottom: 20px;
    right: 15px;
  }
}
</style>

<script>
import StepperInput from "@/components/StepperInput";

export default {
  components: { StepperInput },
  props: ["item"],
  data() {
    return {
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "150px";
        case "lg":
          return "150px";
        case "xl":
          return "150px";
        default:
          return "250px";
      }
    },
    priceLine() {
      return `${this.item.price.toFixed(2)}€/kpl`;
    },
    priceTotal() {
      return (this.item.count * this.item.price).toFixed(2);
    },
    discountPrice() {
      return (
        this.item.count *
        (this.item.price * (1.0 - this.item.discount.percent / 100))
      ).toFixed(2);
    },
  },
  methods: {
    realSku: function(item) {
      if (item.baseProduct) {
        return item.baseProduct.sku;
      } else {
        return item.sku;
      }
    },

    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
    removeFromCart: function(uuid) {
      this.$store.commit("removeFromCart", { uuid: uuid });
    },
  },
};
</script>
