<template>
  <div
    class="
      d-flex
      flex-column
      check-out-delivery
      flex-grow-1
      overflow-hidden
      pb-4
      mx-auto
    "
    style="max-width: 700px"
  >
    <h2 class="pa-5 font-weight-light">Toimitusvaihtoehdot</h2>
    <v-item-group mandatory>
      <!-- Pickup option store -->
      <div>
        <v-divider></v-divider>
        <v-item>
          <v-card
            flat
            tile
            class="d-flex align-center"
            v-on:click="
              setDeliveryMethod({
                name: 'Nouto myymälästä 7vrk kuluessa',
                cost: 0,
              })
            "
          >
            <v-sheet
              width="40"
              height="40"
              class="d-flex justify-center flex-shrink-0 transparent"
            >
              <v-slide-x-transition>
                <v-icon
                  v-if="
                    this.$store.state.deliveryMethod ==
                      'Nouto myymälästä 7vrk kuluessa'
                  "
                  color="success"
                >
                  mdi-check
                </v-icon>
              </v-slide-x-transition>
            </v-sheet>
            <div class="d-flex flex-grow-1 align-center">
              <v-img
                src="@/assets/images/Aitta-delivery.svg"
                class="my-4 mr-4 flex-grow-0"
                contain
                height="40"
                width="40"
              >
              </v-img>
              <div class="d-flex flex-grow-1 body-2 py-4" style="width: 100%">
                <div style="width: 70%">
                  Nouto Ruskon myymälästä 7vrk kuluessa
                </div>
                <div
                  class="font-weight-bold text-right mr-auto"
                  style="width: 60px"
                >
                  0€
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </v-item>
      </div>

      <div v-if="!checkSpecialDelivery">
        <v-divider></v-divider>
        <v-item>
          <v-card
            flat
            tile
            class="d-flex align-center"
            v-on:click="setDeliveryMethod({ name: 'Rahtitoimitus', cost: 0 })"
          >
            <v-sheet
              width="40"
              height="40"
              class="d-flex justify-center flex-shrink-0 transparent"
            >
              <v-slide-x-transition>
                <v-icon
                  v-if="this.$store.state.deliveryMethod == 'Rahtitoimitus'"
                  color="success"
                >
                  mdi-check
                </v-icon>
              </v-slide-x-transition>
            </v-sheet>
            <div class="d-flex flex-grow-1 align-center">
              <v-icon
                class="my-4 mr-4 flex-grow-0"
                contain
                height="40"
                width="40"
              >
                $Icon40TruckDelivery
              </v-icon>
              <div class="d-flex flex-grow-1 body-2 py-4" style="width: 100%">
                <div style="width: 70%">Rahtitoimitus</div>
                <div
                  class="font-weight-bold text-right mr-auto"
                  style="width: 120px"
                >
                  Sovi toimituksesta
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </v-item>
        <div v-if="this.$store.state.deliveryMethod == 'Rahtitoimitus'">
          <v-card flat class="px-8">
            <v-card-title> </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  Tuote voidaan toimittaa rahtitoimituksena. Toimitushinnat
                  vaihtelevat tuotteen ja toimitusosoitteen mukaan. Olethan
                  yhteydessä myymälään saadaksesi lisätietoja.
                </v-col>
              </v-row>
            </v-card-text>
            <br />
          </v-card>
        </div>
      </div>

      <!-- Pick-up location options -->
      <div v-if="checkSpecialDelivery">
        <v-divider></v-divider>
        <v-item>
          <div class="d-flex flex-column">
            <v-card
              class="d-flex align-center"
              flat
              tile
              v-on:click="
                setDeliveryMethod({ name: 'Valitse noutopiste', cost: 7.9 })
              "
            >
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                <v-slide-x-transition>
                  <v-icon
                    v-if="this.$store.state.deliveryMethod.includes('Toimitus')"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </v-slide-x-transition>
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/package-delivery.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div>7.90€ Toimitus noutopisteeseen</div>
                </div>
                <v-spacer></v-spacer>
                <!-- <v-icon class="chevron-up-icon ma-4">mdi-chevron-up</v-icon> -->
                <v-icon class="chevron-up-icon ma-4">{{ this.icon }}</v-icon>
              </div>
            </v-card>
            <v-expand-transition>
              <div
                v-show="this.$store.state.deliveryMethod.includes('noutopiste')"
                style="display: flex"
              >
                <div class="d-flex flex-column flex-grow-1 pt-2 pb-6">
                  <v-text-field
                    label="Postinumero"
                    outlined
                    dense
                    width="200"
                    class="ml-10 mr-auto"
                    v-model="postalCode"
                  >
                  </v-text-field>

                  <v-item-group class="d-flex flex-column">
                    <CheckOutPickupPoint
                      v-for="point in pickupPoints"
                      :key="point.seqNumber"
                      :point="point"
                    />
                  </v-item-group>
                  <v-btn
                    v-if="postalCode"
                    color="primary"
                    text
                    class="ml-6 mr-auto my-4"
                    v-on:click="addToMaxResults(5)"
                  >
                    Näytä lisää
                  </v-btn>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </v-item>
      </div>

      <!-- Pickup nearest post office -->
      <!--
      <div>
        <v-divider></v-divider>
        <v-item v-slot="{ active, toggle }">
          <v-card flat tile class="d-flex align-center" @click="toggle">
            <v-sheet
              width="40"
              height="40"
              class="d-flex justify-center flex-shrink-0 transparent"
            >
              <v-slide-x-transition>
                <v-icon v-if="active" color="success">
                  mdi-check
                </v-icon>
              </v-slide-x-transition>
            </v-sheet>
            <div class="d-flex flex-grow-1 align-center">
              <v-img
                src="@/assets/images/posti-logo.svg"
                class="my-4 mr-4 flex-grow-0"
                contain
                height="40"
                width="40"
              >
              </v-img>
              <div class="d-flex flex-grow-1 body-2 py-4" style="width:100%;">
                <div style="width:70%;">Toimitus lähimpään postiin</div>
                <div
                  class="font-weight-bold text-right mr-auto"
                  style="width:60px;"
                >
                  6.90€
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </v-item>
      </div>
      -->

      <div v-if="checkSpecialDelivery">
        <v-divider></v-divider>
        <v-item>
          <v-card
            flat
            tile
            class="d-flex align-center"
            v-on:click="
              setDeliveryMethod({ name: 'Matkahuolto kotijakelu', cost: 21 })
            "
          >
            <v-sheet
              width="40"
              height="40"
              class="d-flex justify-center flex-shrink-0 transparent"
            >
              <v-slide-x-transition>
                <v-icon
                  v-if="
                    this.$store.state.deliveryMethod == 'Matkahuolto kotijakelu'
                  "
                  color="success"
                >
                  mdi-check
                </v-icon>
              </v-slide-x-transition>
            </v-sheet>
            <div class="d-flex flex-grow-1 align-center">
              <v-img
                src="@/assets/images/matkahuolto-logo.svg"
                class="my-4 mr-4 flex-grow-0"
                contain
                height="40"
                width="40"
              >
              </v-img>
              <div class="d-flex flex-grow-1 body-2 py-4" style="width: 100%">
                <div style="width: 70%">Matkahuolto kotijakelu</div>
                <div
                  class="font-weight-bold text-right mr-auto"
                  style="width: 60px"
                >
                  21.00€
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-card>
        </v-item>
        <div
          v-if="this.$store.state.deliveryMethod == 'Matkahuolto kotijakelu'"
        >
          <v-card flat class="px-8">
            <v-card-title> Toimitusosoite kotijakelua varten </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Katuosoite"
                    v-model="mhStreetAddress"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field label="Postinumero" v-model="mhPostalCode">
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field label="Kaupunki" v-model="mhCity">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    primary
                    depressed
                    rounded
                    color="primary"
                    @click="setDeliveryAddress"
                  >
                    Vahvista toimitusosoite
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <br />
          </v-card>
        </div>
      </div>

      <!-- Delivery onsite -->
      <!--
      <div>
        <v-divider></v-divider>
        <v-item v-slot="{ active, toggle }">
          <div class="d-flex flex-column">
            <v-card class="d-flex align-center" flat tile @click="toggle">
              <v-sheet
                width="40"
                height="40"
                class="d-flex justify-center flex-shrink-0 transparent"
              >
                
              </v-sheet>
              <div class="d-flex flex-grow-1 align-center">
                <v-img
                  src="@/assets/images/truck-delivery.svg"
                  class="my-4 mr-4 flex-grow-0"
                  contain
                  height="40"
                  width="40"
                >
                </v-img>
                <div class="d-flex flex-column flex-grow-1 body-2 py-4">
                  <div>8.99€ - 21.00€ Toimitusperille</div>
                </div>
                <v-spacer></v-spacer>
                <v-icon class="chevron-up-icon ma-4">mdi-chevron-up</v-icon>
              </div>
            </v-card>
            <v-expand-transition>
              <div v-show="active" style="display: flex;">
                <div class="d-flex flex-column flex-grow-1 pt-2 pb-6">
                  <v-item-group class="d-flex flex-column">
                    <v-item
                      v-slot="{ active, toggle }"
                      @click="toggle"
                      v-for="n in 3"
                      :key="n"
                    >
                      <v-card
                        class="d-flex align-center flex-grow-1"
                        flat
                        tile
                        @click="toggle"
                      >
                        <v-sheet
                          width="40"
                          height="40"
                          class="d-flex justify-center flex-shrink-0 transparent"
                        >
                          <v-slide-x-transition>
                            <v-icon v-if="active" color="success">
                              mdi-check
                            </v-icon>
                          </v-slide-x-transition>
                        </v-sheet>
                        <v-img
                          src="@/assets/images/matkahuolto-logo.svg"
                          class="my-4 mr-4 flex-grow-0"
                          contain
                          height="40"
                          width="40"
                        >
                        </v-img>
                        <div class="d-flex align-center" style="width:100%">
                          <div
                            class="d-flex flex-column body-2 py-4"
                            style="width:70%"
                          >
                            <div class="font-weight-bold">
                              Matkahuollon kotijakelu
                            </div>
                          </div>
                          <div
                            class="font-weight-bold text-right mr-auto"
                            style="width:60px"
                          >
                            21.00€
                          </div>
                        </div>
                      </v-card>
                    </v-item>
                  </v-item-group>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </v-item>
      </div>
      -->
    </v-item-group>
    <v-divider></v-divider>
  </div>
</template>

<style scoped>
.v-item--active .chevron-up-icon {
  transform: rotate(-180deg);
}
</style>

<script>
import CheckOutPickupPoint from "@/components/check-out/CheckOutPickupPoint";
import { mapGetters } from "vuex";
export default {
  components: { CheckOutPickupPoint },
  data: () => ({
    deliveryPickupPoinstUrl:
      process.env.VUE_APP_BBSHOP_ENDPOINT + "/matkahuolto/pickup-point",
    login: "1234567",
    postalCode: "",
    pickupPoints: [],
    maxResults: 5,
    selectedPickupPoint: "",
    mhStreetAddress: "",
    mhPostalCode: "",
    mhCity: "",
    icon: "mdi-chevron-down",
  }),
  created: function() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // matkahuolto api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedGetPickupPoints = this._.debounce(this.getPickupPoints, 500);
  },
  watch: {
    postalCode() {
      this.debouncedGetPickupPoints();
    },
  },
  methods: {
    setDeliveryMethod: function(methodText) {
      this.$store.commit("setDeliveryMethod", methodText);
      if (methodText.name === "Valitse noutopiste") {
        this.icon = "mdi-chevron-up";
      } else {
        this.icon = "mdi-chevron-down";
      }
    },
    setDeliveryAddress: function() {
      this.$store.commit("setDeliveryAddress", {
        streetAddress: this.mhStreetAddress,
        postalCode: this.mhPostalCode,
        city: this.mhCity,
      });
    },
    getPickupPoints() {
      this.axios
        .post(this.deliveryPickupPoinstUrl, {
          login: this.login,
          version: 1,
          postalCode: this.postalCode,
          country: "FI",
          "responseType:": "XML",
          maxResults: this.maxResults,
          coordinates: "Y",
        })
        .then((response) => {
          // console.log(response);
          this.pickupPoints = response.data.item.office;
        })
        // TODO Actual error handling
        .catch((error) => {
          console.log(error);
        });
    },
    addToMaxResults(value) {
      this.maxResults += value;
      this.getPickupPoints();
    },
  },
  computed: {
    ...mapGetters(["checkSpecialDelivery"]),
  },
};
</script>
